import React, {useState} from "react";
import {useNavigate} from "react-router-dom";
import axios from "axios";
import {
    Header,
    LogoWrapper,
    StyledButton,
    StyledCard,
    StyledContainer,
    StyledForm,
    StyledLink,
    StyledLinkWrapper,
    StyledTextField,
} from "./Register.styled";
import Logo from "../../assets/images/asystent_it_logo.png";

async function registerUser(name: string, surname: string, email: string, password: string) {
    try {
        await axios.post(`/auth/register`, {
            email,
            password,
            role: "USER"
        });
    } catch (error) {
        alert("Rejestracja nie powiodła się. Spróbuj ponownie.");
        console.error("Błąd rejestracji:", error);
    }
}

const Register: React.FC = () => {
    const [name, setName] = useState("");
    const [surname, setSurname] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const navigate = useNavigate();

    const handleRegister = async () => {
        await registerUser(name, surname, email, password);
        navigate("/login");
    };

    return (
        <StyledContainer>
            <StyledCard>
                <LogoWrapper>
                    <img src={Logo} alt="Asystent IT Logo"/>
                </LogoWrapper>
                <Header>Rejestracja</Header>
                <StyledForm autoComplete="off">
                    <StyledTextField
                        label="Imię"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                    />
                    <StyledTextField
                        label="Nazwisko"
                        value={surname}
                        onChange={(e) => setSurname(e.target.value)}
                    />
                    <StyledTextField
                        label="Email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                    <StyledTextField
                        label="Hasło"
                        type="password"
                        autoComplete="new-password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                    <StyledButton onClick={handleRegister}>Zarejestruj się</StyledButton>
                    <StyledLinkWrapper>
                        <span>Masz już konto?</span>
                        <StyledLink onClick={() => navigate('/login')}>
                            <strong>Zaloguj się</strong>
                        </StyledLink>
                    </StyledLinkWrapper>
                </StyledForm>
            </StyledCard>
        </StyledContainer>
    );
};

export default Register;
