import styled from "styled-components";
import {Button} from "@mui/material";

export const StyledContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 64px auto auto auto;
    width: 90%;
    max-width: 600px;
    background: #ffffff;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    border-radius: 20px;
    padding: 64px;
`;

export const Header = styled.div`
    width: 100%;
    margin-bottom: 24px;

    h2 {
        font-size: 24px;
        font-weight: 600;
        text-align: center;
    }
`;

export const ButtonWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    gap: 36px;
    width: 100%;
`;

export const StyledButton = styled(Button)`
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 12px;
    font-size: 18px !important;
    font-weight: bold;
    text-transform: none;

    .MuiButton-startIcon {
        margin: 0 !important;
    }

    svg {
        margin: 8px 0;
        width: 36px;
        height: 36px;
    }
`;
