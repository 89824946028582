import React, {useEffect, useState} from 'react';
import axios from 'axios';
import {Header, StyledContainer} from './AdminCollectionList.styled';
import CreateCollectionDialog from "../../components/collections/CreateCollectionDialog";
import CollectionPermissionDialog from "../../components/collections/CollectionPermissionDialog";
import {useNavigate} from "react-router-dom";
import {DocumentCollection} from "../../models/models";
import {ReactComponent as FolderPlusIcon} from "../../assets/icons/folder-plus.svg";
import CollectionTable from "../../components/collections/CollectionTable";
import {ActionButton} from "../../components/collections/CollectionTable.styled";
import {transformCollections} from "../../utils/format-collections";


export interface EditDocumentCollectionPermission {
    id: number;
    owner: string
}

const AdminCollectionList: React.FC = () => {
    const [collections, setCollections] = useState<DocumentCollection[]>([]);
    const [loading, setLoading] = useState(true);
    const [isCreateDialogOpen, setIsCreateDialogOpen] = useState(false);
    const [selectedEditDocumentCollectionPermission, setSelectedEditDocumentCollectionPermission] = useState<EditDocumentCollectionPermission | null>(null)
    const [showPermissionDialog, setShowPermissionDialog] = useState(false);
    const navigate = useNavigate();

    const fetchCollections = async () => {
        try {
            const response = await axios.get('/documents/by-collection');
            setCollections(transformCollections(response.data.content));
        } catch (error) {
            console.error('Error fetching collections:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchCollections();
    }, []);

    const handleDelete = async (collectionId: number) => {
        try {
            await axios.delete(`/collections/${collectionId}/documents`);
            setCollections(collections.filter(c => c.id !== collectionId));
        } catch (error) {
            console.error('Error deleting collection:', error);
        }
    };

    const handleManagePermissions = (editDocumentCollectionPermission: EditDocumentCollectionPermission) => {
        setSelectedEditDocumentCollectionPermission(editDocumentCollectionPermission);
        setShowPermissionDialog(true);
    }

    const handleCollectionPreview = (collectionId: number) => {
        navigate(`/collections/${collectionId}/documents`)
    }

    if (loading) return <div>Loading...</div>;

    return (
        <StyledContainer>
            <Header>
                <h2>Kolekcje Dokumentów</h2>
                <ActionButton
                    variant="contained"
                    onClick={() => setIsCreateDialogOpen(true)}
                    startIcon={<FolderPlusIcon/>}
                >
                    Utwórz Kolekcje
                </ActionButton>
            </Header>

            <CollectionTable
                collections={collections}
                isAdmin={true}
                onPreview={handleCollectionPreview}
                onManagePermissions={handleManagePermissions}
                onDelete={handleDelete}
            />
            <CreateCollectionDialog
                open={isCreateDialogOpen}
                onClose={() => setIsCreateDialogOpen(false)}
                onCollectionCreated={() => fetchCollections()}
            />

            {selectedEditDocumentCollectionPermission && (
                <CollectionPermissionDialog
                    open={showPermissionDialog}
                    collectionId={selectedEditDocumentCollectionPermission.id}
                    owner={selectedEditDocumentCollectionPermission.owner}
                    onClose={() => setShowPermissionDialog(false)}
                />)}

        </StyledContainer>
    );
};

export default AdminCollectionList;

