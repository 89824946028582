import React, {useState} from 'react';
import axios from 'axios';
import {
    CancelButton,
    Input,
    ModalBody,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    StyledDialog,
    SubmitButton
} from './AddDocumentDialog.styled';
import {UploadDocumentResponse} from "../../models/models";

interface Props {
    open: boolean;
    onClose: () => void;
    collectionId: string,
    onDocumentCreated: () => void;
}

const AddDocumentDialog: React.FC<Props> = ({open, onClose, collectionId, onDocumentCreated}) => {
    const [newDocumentName, setNewDocumentName] = useState('');
    const [newDocumentFile, setNewDocumentFile] = useState<File | null>(null);

    const handleSubmit = async (event: React.FormEvent) => {
        event.preventDefault();
        if (!newDocumentFile || !newDocumentName || !collectionId) {
            alert('Proszę wypełnić wszystkie pola.');
            return;
        }
        try {
            await uploadDocument(newDocumentFile, newDocumentName, collectionId);
            onDocumentCreated()
            closeModal();
        } catch (error) {
            console.error('Failed to upload document:', error);
            alert('Wystąpił błąd podczas dodawania dokumentu.');
        }
    };

    const uploadDocument = async (file: File, name: string, collectionId?: string): Promise<UploadDocumentResponse> => {
        const formData = new FormData();
        formData.append('file', file);
        formData.append('name', name);
        if (collectionId) {
            formData.append('collectionId', collectionId);
        }

        const response = await axios.post<UploadDocumentResponse>('/documents', formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });

        return response.data;
    };

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files && event.target.files.length > 0) {
            setNewDocumentFile(event.target.files[0]);
        }
    };

    const closeModal = () => {
        setNewDocumentName('');
        setNewDocumentFile(null);
        onClose()
    };

    return (
        <StyledDialog open={open} onClose={closeModal}>
            <ModalOverlay>
                <ModalContent>
                    <ModalHeader>Dodaj nowy Dokument</ModalHeader>
                    <form onSubmit={handleSubmit}>
                        <ModalBody>
                            <Input
                                type="text"
                                placeholder="Nazwa dokumentu"
                                value={newDocumentName}
                                onChange={(e) => setNewDocumentName(e.target.value)}
                                required
                            />
                            <Input
                                type="file"
                                accept=".pdf,.doc,.docx"
                                onChange={handleFileChange}
                                required
                            />
                        </ModalBody>
                        <ModalFooter>
                            <SubmitButton type="submit">Dodaj</SubmitButton>
                            <CancelButton type="button" onClick={closeModal}>
                                Anuluj
                            </CancelButton>
                        </ModalFooter>
                    </form>
                </ModalContent>
            </ModalOverlay>
        </StyledDialog>
    );
};

export default AddDocumentDialog;
