import React, {useEffect, useState} from "react";
import {Button, TextField, Typography} from "@mui/material";
import axios from "axios";
import {
    Header,
    StyledContainer,
    StyledTable,
    StyledTableCard,
    StyledTableCell,
    StyledTableRow,
} from "./CollectionUpdateScheduler.styled";

const CollectionUpdateScheduler: React.FC = () => {
    const [jobs, setJobs] = useState<any[]>([]);
    const [updateFrequencies, setUpdateFrequencies] = useState<{ [key: number]: string }>({});
    const [error, setError] = useState("");

    const fetchJobs = async () => {
        try {
            const response = await axios.get("/jobs");
            setJobs(response.data);
            const initialFrequencies = response.data.reduce((acc: any, job: any) => {
                acc[job.id] = job.cronExpression;
                return acc;
            }, {});
            setUpdateFrequencies(initialFrequencies);
        } catch (error) {
            console.error("Error fetching jobs:", error);
        }
    };

    useEffect(() => {
        fetchJobs();
    }, []);

    const updateFrequency = async (id: number) => {
        try {
            await axios.put(`/jobs/${id}`, {cronExpression: updateFrequencies[id]});
            setError("");
            await fetchJobs();
        } catch (error: any) {
            setError("Wystąpił błąd podczas aktualizacji częstotliwości aktualizacji kolekcji.");
        }
    };

    return (
        <StyledContainer>
            <Header>
                <h2>Harmonogram Aktualizacji Kolekcji</h2>
            </Header>
            {error && <Typography color="error">{error}</Typography>}
            {jobs.length > 0 ? (
                <StyledTableCard>
                    <StyledTable>
                        <thead>
                        <tr>
                            <StyledTableCell>Nazwa Kolekcji</StyledTableCell>
                            <StyledTableCell>Ostatnia Aktualizacja</StyledTableCell>
                            <StyledTableCell>Następna Aktualizacja</StyledTableCell>
                            <StyledTableCell>Status</StyledTableCell>
                            <StyledTableCell style={{textAlign: "center"}}>Częstotliwość Aktualizacji</StyledTableCell>
                            <StyledTableCell>Akcja</StyledTableCell>
                        </tr>
                        </thead>
                        <tbody>
                        {jobs.map((job) => (
                            <StyledTableRow key={job.id}>
                                <StyledTableCell>{job.name}</StyledTableCell>
                                <StyledTableCell>
                                    {new Date(job.prevFireTime).getFullYear() < 2000
                                        ? "-"
                                        : new Date(job.prevFireTime).toLocaleString()}
                                </StyledTableCell>
                                <StyledTableCell>{new Date(job.nextFireTime).toLocaleString()}</StyledTableCell>
                                <StyledTableCell>{job.isRunning ? "Aktywna" : "Nieaktywna"}</StyledTableCell>
                                <StyledTableCell style={{textAlign: "center"}}>
                                    <TextField
                                        variant="standard"
                                        value={updateFrequencies[job.id]}
                                        onChange={(e) =>
                                            setUpdateFrequencies({...updateFrequencies, [job.id]: e.target.value})
                                        }
                                        sx={{textAlign: "center", mx: "auto", display: "block"}}
                                    />
                                </StyledTableCell>

                                <StyledTableCell>
                                    <Button
                                        variant="outlined"
                                        onClick={() => updateFrequency(job.id)}
                                        disabled={updateFrequencies[job.id] === job.cronExpression}
                                    >
                                        Zapisz
                                    </Button>
                                </StyledTableCell>
                            </StyledTableRow>
                        ))}
                        </tbody>
                    </StyledTable>
                </StyledTableCard>
            ) : (
                <h2 style={{textAlign: "center", marginTop: "24px"}}>Brak kolekcji do aktualizacji</h2>
            )}
        </StyledContainer>
    );
};

export default CollectionUpdateScheduler;
