import React from 'react';
import {Navigate, Outlet, useLocation} from 'react-router-dom';
import {useAuth} from '../core/AuthContext';
import LoadingSpinner from "./LoadingSpinner";

const PrivateRoute: React.FC = () => {
    const {isAuthenticated, isVerifying} = useAuth();
    const location = useLocation();

    if (isVerifying) {
        return <LoadingSpinner/>;
    }

    return isAuthenticated ? (
        <Outlet/>
    ) : (
        <Navigate to="/login" replace state={{from: location}}/>
    );
};

export default PrivateRoute;
