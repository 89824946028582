import styled from "styled-components";
import {Button, Container, Link, TextField} from "@mui/material";

export const StyledContainer = styled(Container)`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100%;
`;

export const StyledCard = styled.div`
    background: #ffffff;
    padding: 40px;
    border-radius: 16px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    text-align: center;
    width: 100%;
    max-width: 500px;
    border: 1px solid #e5edf3;
    margin: 10% auto;
`;

export const StyledForm = styled.form`
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-top: 24px;
`;

export const StyledButton = styled(Button)`
    && {
        margin-top: 16px;
        padding: 12px;
        font-size: 16px;
        font-weight: bold;
        background-color: #007db8;
        color: #ffffff;

        &:hover {
            background-color: #005f8c;
        }
    }
`;

export const Header = styled.h1`
    font-size: 24px;
    font-weight: bold;
    margin: 16px 0;
`;

export const LogoWrapper = styled.div`
    text-align: center;
    margin-bottom: 16px;

    img {
        width: 80px;
        height: auto;
    }
`;

export const StyledTextField = styled(TextField)`
    && {
        .MuiOutlinedInput-root {
            border-radius: 12px;
            background-color: transparent;

            &.Mui-focused .MuiOutlinedInput-notchedOutline {
                border-color: #007db8;
            }
        }

        .MuiOutlinedInput-notchedOutline {
            border-color: #a7b2bc;
        }

        .MuiInputLabel-root {
            color: #5a5a5a;

            &.Mui-focused {
                color: #007db8;
            }
        }

        input:-webkit-autofill {
            box-shadow: 0 0 0px 1000px white inset;
            -webkit-text-fill-color: #000000;
            font-size: 1rem;
        }
    }
`;

export const StyledLink = styled(Link)`
    font-size: 14px;
    color: #007db8;
    text-decoration: none;
    cursor: pointer;

    &:hover {
        text-decoration: underline;
        color: #005f8c;
    }
`;

export const StyledLinkWrapper = styled.div`
    margin-top: 12px;
    text-align: center;

    span {
        margin-right: 10px;
    }
`;
