import styled from 'styled-components';
import {IconButton, TextField} from '@mui/material';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
`

export const StyledForm = styled.form`
    position: relative;
    display: flex;
    align-items: center;
    border: 1px solid #cacfd2;
    border-radius: 8px;
    background: #ffffff;
    padding-left: 4px;
`;

export const MicrophoneButton = styled(IconButton)<{ $isRecording: boolean }>`
    color: ${(props) => (props.$isRecording ? 'red' : '#a7b2bc') + ' !important'};
`

export const InputActions = styled.div`
    display: flex;
    align-items: center;
    margin-right: 8px;

    button {
        width: 36px;
        height: 36px;
        padding: 0;
        color: #a7b2bc;
    }
`;

export const StyledTextField = styled(TextField)`
    && {
        flex: 1;
        border: none;

        .MuiOutlinedInput-root {
            border: none;
            font-size: 16px;
            padding: 8px 0;
        }

        .MuiOutlinedInput-notchedOutline {
            display: none;
        }

        .MuiInputBase-input {
            padding: 0;
        }
    }
`;

export const SendButtonWrapper = styled.div`
    display: flex;
    align-items: center;
    margin-left: 8px;

    button {
        width: 44px;
        height: 41px;
        padding: 0;
        background: #f0f0f0;
        border-radius: 16px 8px 8px 16px;
        border-left: 1px solid #cacfd2;
        display: flex;
        align-items: center;
        justify-content: center;

        &:hover {
            background: #e0ddde;
        }

        &:disabled {
            opacity: 0.7;
        }
    }
`;
export const FileInfoWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #f5f5f5;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 8px 12px;
    width: max-content;
    margin-top: 10px;
    gap: 8px;
`;

export const RemoveButton = styled.button`
    background: none;
    border: none;
    color: #595959;
    cursor: pointer;
    font-size: 14px;
    bottom: 1px;
    font-weight: bold;
    position: relative;

    &::after {
        content: '✕';
    }
`;
