import React, {useEffect, useState} from 'react';
import {List, ListItem, Menu, MenuItem} from '@mui/material';

import MenuSvg from "assets/icons/menu.svg";
import Settings2Svg from "assets/icons/settings-02.svg";
import EditSvg from "assets/icons/edit.svg";
import ChevronRightSvg from "assets/icons/chevron-right.svg";
import FileQuestionSvg from "assets/icons/file-question.svg";
import MessageCheckSvg from "assets/icons/message-check-square.svg";
import HelpCircleSvg from "assets/icons/help-circle.svg";
import AlertTriangleSvg from "assets/icons/alert-triangle.svg";
import MoreSvg from "assets/icons/more.svg";
import LogoPng from "assets/images/logo.png";
import AvatarPng from "assets/images/avatar.png";
import {
    Divider,
    LogoBox,
    PanelAdminButton,
    ProfileContainer,
    ProfileMoreBtn,
    SidebarContainer,
    SidebarHeader,
    StyledButton,
    StyledIconButton,
    StyledList,
    StyledNewRequestButton,
    StyledReportProblemButton,
    StyledReportProblemWrapper
} from './Sidebar.styled';
import {useUser} from "../core/UserContext";
import {useAuth} from "../core/AuthContext";
import {useNavigate} from "react-router-dom";

const Sidebar = ({isOpen, onToggle}: { isOpen: boolean; onToggle: () => void }) => {
    const {user, fetchUserData} = useUser();
    const {logout} = useAuth()
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const navigate = useNavigate()

    useEffect(() => {
        if (!user) {
            void fetchUserData();
        }
    }, [user, fetchUserData]);

    const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const handleLogoutClick = () => {
        handleMenuClose();
        logout();
    };


    return (
        <SidebarContainer variant="permanent" open={isOpen}>
            <SidebarHeader>
                <LogoBox>
                    {isOpen && <img src={LogoPng} alt="Logo"/>}
                </LogoBox>
                <StyledIconButton onClick={onToggle}>
                    <img src={MenuSvg} alt="Menu"/>
                </StyledIconButton>
            </SidebarHeader>

            <StyledList open={isOpen}>
                <List>
                    <PanelAdminButton onClick={() => navigate('/panel')}>
                        <img src={Settings2Svg} alt="Settings"/>
                        {isOpen && <span>Panel Administratora</span>}
                    </PanelAdminButton>
                    <ListItem disablePadding>
                        <StyledNewRequestButton onClick={() => navigate('/chat')}>
                            <img src={EditSvg} alt="Edit"/>
                            {isOpen && <span>Nowe zapytanie</span>}
                            {isOpen && <img src={ChevronRightSvg} alt="Arrow"/>}
                        </StyledNewRequestButton>
                    </ListItem>
                    <Divider/>
                    <StyledButton>
                        <img src={FileQuestionSvg} alt="File Question"/>
                        {isOpen && <span>Materiały Szkoleniowe</span>}
                    </StyledButton>
                    <StyledButton>
                        <img src={MessageCheckSvg} alt="Message Check"/>
                        {isOpen && <span>Zgłoszone sprawy</span>}
                    </StyledButton>
                    <StyledButton>
                        <img src={HelpCircleSvg} alt="Help"/>
                        {isOpen && <span>Poproś o wsparcie</span>}
                    </StyledButton>
                    {isOpen && (
                        <>
                            <Divider/>
                            <div>
                                <div className="category-header">Kwiecień</div>
                                <StyledButton>
                                    <img src={FileQuestionSvg} alt="Document"/>
                                    Konto bankowe
                                </StyledButton>
                                <div className="category-header">Marzec</div>
                                <StyledButton>
                                    <img src={FileQuestionSvg} alt="Document"/>
                                    Konto bankowe
                                </StyledButton>
                            </div>
                        </>
                    )}
                    <Divider/>

                </List>
            </StyledList>
            <StyledReportProblemWrapper open={isOpen}>
                <StyledReportProblemButton>
                    <img src={AlertTriangleSvg} alt="Alert"/>
                    {isOpen && <span>Zgłoś problem</span>}
                </StyledReportProblemButton>
            </StyledReportProblemWrapper>
            <ProfileContainer>
                <img src={AvatarPng} alt="Profile" width="40"/>
                {isOpen && <span>{user?.name} {user?.surname}</span>}
                <ProfileMoreBtn onClick={handleMenuOpen}>
                    <img src={MoreSvg} alt="More"/>
                </ProfileMoreBtn>
                <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleMenuClose}>
                    <MenuItem onClick={handleLogoutClick}>Wyloguj</MenuItem>
                </Menu>
            </ProfileContainer>
        </SidebarContainer>
    );
};

export default Sidebar;
