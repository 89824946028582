import styled from 'styled-components';
import {Button} from "@mui/material";

export const StyledContainer = styled.div`
    padding: 64px;
    max-width: 1200px;
    background: #ffffff;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    margin: 0 auto;
    border-radius: 20px;
    position: relative;
`;

export const BackBtn = styled(Button)`
    position: absolute !important;
    top: 16px !important;
    left: 25px !important;
`

export const Header = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2rem;
`;
