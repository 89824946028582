import styled from "styled-components";

export const StyledContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin: 40px auto;
    width: 90%;
    max-width: 715px;
    background: #ffffff;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    border-radius: 20px;
    padding: 48px;
`;

export const Header = styled.div`
    width: 100%;
    margin-bottom: 16px;
`;

export const StyledTableCard = styled.div`
    margin-top: 16px;
    padding: 16px 0;
    width: 100%;
    border-radius: 20px;
    border: 2px solid #e8e8e8;
`;

export const StyledTable = styled.table`
    width: 100%;
    border-collapse: collapse;

    thead {
        font-weight: bold;
        font-size: 18px;
    }
`;

export const StyledTableRow = styled.tr`
    &:nth-child(even) {
        background-color: #f5f7fa;
    }

    transition: background-color 200ms ease;

    &:hover {
        background-color: rgba(225, 235, 245, 0.7);
    }
`;

export const StyledTableCell = styled.td`
    padding: 16px;
    text-align: left;
    border-bottom: 1px solid #e5edf3;
`;
