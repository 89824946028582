import React, {useEffect, useState} from "react";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Typography,} from "@mui/material";
import {
    Header,
    StyledContainer,
    StyledTable,
    StyledTableCard,
    StyledTableCell,
    StyledTableRow,
} from "./GroupList.styled";
import axios from "axios";
import {ReactComponent as UsersPlusIcon} from "assets/icons/users-plus.svg";
import {formatDate} from "../utils/format-date";
import {useNavigate} from "react-router-dom";
import {ReactComponent as TrashIcon} from "assets/icons/trash.svg";
import {ReactComponent as ArrowCircleRight} from "assets/icons/arrow-circle-right.svg";

const fetchGroups = async () => {
    try {
        const response = await axios.get("/groups");
        return response.data.content as { id: number; name: string; createdAt: string }[];
    } catch (error) {
        console.error("Error fetching groups:", error);
        alert("Wystąpił błąd podczas pobierania listy grup.");
        return [];
    }
};
const removeGroupForId = async (id: number) => {
    try {
        const response = await axios.delete(`/groups/${id}`);
        return response;
    } catch (error) {
        alert("Wystąpił błąd podczas próby usunięcia grupy");
        return [];
    }
};
const GroupList: React.FC = () => {
    const [groups, setGroups] = useState<{ id: number; name: string; createdAt: string }[]>([]);
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [groupName, setGroupName] = useState("");
    const navigate = useNavigate();
    const [error, setError] = useState("");

    useEffect(() => {
        const fetchData = async () => {
            const fetchedGroups = await fetchGroups();
            setGroups(fetchedGroups);
        };
        fetchData();
    }, []);
    useEffect(() => {
        if (!isDialogOpen) {
            setGroupName("")
        }
    }, [isDialogOpen]);

    const handleCreateGroup = async () => {
        try {
            await axios.post("/groups", {groupName});
            setIsDialogOpen(false);
            const fetchedGroups = await fetchGroups();
            setGroups(fetchedGroups);
            setGroupName("");
            setError("")
        } catch (error) {
            setError("")
            setError("Nazwa grupy już istnieje.");
            // alert("Wystąpił błąd podczas tworzenia grupy.");
        }
    };

    const navigateToGroupDetails = (id: number, name: string) => {
        navigate(`/group/${id}?name=${name}`)
    }

    const removeGroup = async (id: number) => {
        await removeGroupForId(id)
        const fetchedGroups = await fetchGroups();
        setGroups(fetchedGroups);
    }

    return (
        <StyledContainer>
            <Header>
                <h2>Lista Grup</h2>
            </Header>
            <Button
                startIcon={<UsersPlusIcon/>}
                sx={{
                    width: "max-content",
                    marginLeft: "auto",
                    marginBottom: "16px"
                }}
                variant="contained" color="primary" onClick={() => setIsDialogOpen(true)}>
                Utwórz Grupę
            </Button>
            <StyledTableCard>
                <StyledTable>
                    <thead>
                    <tr>
                        <StyledTableCell>Nazwa Grupy</StyledTableCell>
                        <StyledTableCell>Data Utworzenia</StyledTableCell>
                        <StyledTableCell>Akcje</StyledTableCell>
                    </tr>
                    </thead>
                    <tbody>
                    {groups.map((group) => (
                        <StyledTableRow key={group.id}>
                            <StyledTableCell>{group.name}</StyledTableCell>
                            <StyledTableCell>
                                {formatDate(group.createdAt)}
                            </StyledTableCell>
                            <StyledTableCell>
                                <Button variant="outlined" color="primary" sx={{marginRight: "8px;"}}
                                        onClick={() => removeGroup(group.id)}>
                                    <TrashIcon/>
                                </Button>
                                <Button variant="outlined" color="primary"
                                        onClick={() => navigateToGroupDetails(group.id, group.name)}>
                                    <ArrowCircleRight/>
                                </Button>
                            </StyledTableCell>
                        </StyledTableRow>
                    ))}
                    </tbody>
                </StyledTable>
            </StyledTableCard>

            <Dialog open={isDialogOpen} onClose={() => setIsDialogOpen(false)}>
                <DialogTitle>Utwórz Nową Grupę</DialogTitle>
                <DialogContent sx={{padding: "32px 64px !important", width: "400px"}}>
                    <TextField
                        label="Nazwa Grupy"
                        fullWidth
                        value={groupName}
                        onChange={(e) => setGroupName(e.target.value)}
                    />
                    <Typography
                        color="error"
                        style={{opacity: error ? 1 : 0, marginTop: "8px", height: "30px"}}
                    >
                        {error}
                    </Typography> </DialogContent>
                <DialogActions sx={{paddingBottom: "20px", paddingRight: "20px"}}>
                    <Button onClick={() => {
                        setIsDialogOpen(false)
                    }}>Anuluj</Button>
                    <Button variant="contained" color="primary" onClick={handleCreateGroup}>
                        Utwórz
                    </Button>
                </DialogActions>
            </Dialog>
        </StyledContainer>
    );
};

export default GroupList;
