import styled from "styled-components";

export const StyledContainer = styled.div`
   padding: 32px;
   max-width: 1200px;
   margin: 0 auto;
 `;

export const Header = styled.div`
   margin-bottom: 8px;
   display: flex;
   justify-content: space-between;
   align-items: center;
 `;

export const StyledTableCard = styled.div`
   background: white;
   border-radius: 8px;
   box-shadow: 0 2px 4px rgba(0,0,0,0.1);
   overflow: hidden;
 `;

export const StyledTable = styled.table`
   width: 100%;
   border-collapse: collapse;
 `;

export const StyledTableCell = styled.td`
   padding: 16px;
   border-bottom: 1px solid #eee;

   &:first-child {
     padding-left: 32px;
   }

   &:last-child {
     padding-right: 32px;
   }
 `;

export const StyledTableRow = styled.tr`
   &:hover {
     background-color: #f5f5f5;
   }
 `;

export const StyledNoDocumentsCell = styled(StyledTableCell)`
   text-align: center;
   padding: 40px 0;
   font-size: 1.2rem;
   color: #666;
 `;

export const ActionButtons = styled.div`
   display: flex;
   gap: 8px;
 `;