import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {
    AddUserWrapper,
    Header,
    StyledContainer,
    StyledTable,
    StyledTableCard,
    StyledTableCell,
    StyledTableRow
} from "./UserList.styled";
import {ReactComponent as UserPlusIcon} from "assets/icons/user-plus.svg";
import {ReactComponent as UserEditIcon} from "assets/icons/user-edit.svg";
import {ReactComponent as UserDeleteIcon} from "assets/icons/user-minus.svg";
import {Button} from "@mui/material";
import axios from "axios";
import {User, UserRole} from "../models/models";

export async function fetchUsers() {
    try {
        const result = await axios.get(`/users`);

        return result.data.content as User[]
    } catch (error) {
        alert("Błąd podczas uzyskiwania listy użytkowników.");
        console.error("Błąd rejestracji:", error);

    }
}

const UserList: React.FC = () => {
    const navigate = useNavigate();
    const [users, setUsers] = useState<User[]>([])
    const roleLabels: Record<UserRole, string> = {
        [UserRole.SYSTEM_ADMIN]: "Administrator",
        [UserRole.USER]: "Użytkownik"
    };
    useEffect(() => {
        const fetchData = async () => {
            const users = await fetchUsers();
            if (users) {
                setUsers(users)
            }
        };
        fetchData();
    }, []);

    const navigateToEditUser = (user: User) => {
        const params = {
            ...user,
            createdAt: new Date(user.createdAt).toISOString(),
        };
        const queryParams = new URLSearchParams(params as any).toString();
        navigate(`/user/edit?${queryParams}`);
    };

    return (
        <StyledContainer>
            <Header>
                <h2>
                    Lista Użytkowników
                </h2>
            </Header>
            <AddUserWrapper>
                <Button variant={'contained'} onClick={() => navigate("/user/create")}
                        startIcon={<UserPlusIcon/>} sx={{marginBottom: '8px'}}>
                    Utwórz użytkownika
                </Button>
            </AddUserWrapper>
            <StyledTableCard>
                <StyledTable>
                    <thead>
                    <tr>
                        <StyledTableCell>Email</StyledTableCell>
                        <StyledTableCell>Rola</StyledTableCell>
                        <StyledTableCell>Aktywny</StyledTableCell>
                        <StyledTableCell>Akcje</StyledTableCell>
                    </tr>
                    </thead>
                    <tbody>
                    {users.map((user) => (
                        <StyledTableRow key={user.email}>
                            <StyledTableCell>{user.email}</StyledTableCell>
                            <StyledTableCell>{roleLabels[user.role] || 'Brak'}</StyledTableCell>
                            <StyledTableCell>{user.activated ? 'Tak' : 'Nie'}</StyledTableCell>
                            <StyledTableCell>
                                <Button variant={'outlined'} sx={{marginRight: '8px'}}
                                        onClick={() => navigate("/user/create")}
                                ><UserDeleteIcon/>
                                </Button>
                                <Button variant={'outlined'} onClick={() => navigateToEditUser(user)}
                                ><UserEditIcon/>
                                </Button>
                            </StyledTableCell>
                        </StyledTableRow>
                    ))}
                    </tbody>
                </StyledTable>
            </StyledTableCard>
        </StyledContainer>
    );
};

export default UserList;
