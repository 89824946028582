import {useCallback, useState} from 'react';
import axios from "axios";
import {Category, PdfDocument, UploadDocumentResponse} from "../models/models";
import {accessTokenKey} from "../core/AuthInterceptor";

const BASE_URL = process.env.REACT_APP_AI_ASSISTANT_BACKEND_URL;

export const useApi = () => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<string | null>(null);

    const sendMessage = async (
        question: string,
        onUpdate: (partialResponse: string) => void,
        file: File | null,
        conversationId: string,
        onFinish: () => void
    ): Promise<void> => {
        setError(null);
        setLoading(true);

        const token = localStorage.getItem(accessTokenKey);
        const url = `${BASE_URL}/chat?question=${encodeURIComponent(question)}&conversationId=${conversationId}`;

        const formData = new FormData();
        if (file) {
            formData.append("file", file);
        }

        try {
            const response = await fetch(url, {
                method: "POST",
                headers: {
                    Authorization: `Bearer ${token}`,
                    Accept: "text/event-stream",
                },
                body: formData,
            });

            if (!response.ok || !response.body) {
                throw new Error("Błąd połączenia ze strumieniem");
            }

            const reader = response.body.getReader();
            const decoder = new TextDecoder();
            let [fullResponse, processing] = ["", true];

            const processStream = async () => {
                while (processing) {
                    const {done, value} = await reader.read();
                    if (done) break;

                    let partialResponse = decoder.decode(value, {stream: true});
                    partialResponse = partialResponse.replace(/^data:/, '').trimStart();
                    if (partialResponse.includes("_END_")) {
                        processing = false;
                        break;
                    }
                    fullResponse += partialResponse;
                    onUpdate(fullResponse);
                }
            };

            await processStream();
        } catch (error) {
            onUpdate("Błąd połączenia ze strumieniem");
        } finally {
            setLoading(false);
            onFinish();
        }
    };

    const fetchCategories = useCallback(async (): Promise<Category[]> => {
        setLoading(true);
        setError(null);
        try {
            const response = await axios.get<Category[]>(`/categories`);
            return response.data;
        } catch (err: any) {
            setError(err.response?.data?.message || 'Something went wrong');
            throw err;
        } finally {
            setLoading(false);
        }
    }, []);

    const fetchDocumentsByCategoryId = async (id: string): Promise<PdfDocument[]> => {
        setLoading(true);
        setError(null);
        try {
            const response = await axios.get<PdfDocument[]>(`/categories/${id}/documents`);
            return response.data;
        } catch (err: any) {
            setError(err.response?.data?.message || 'Something went wrong');
            throw err;
        } finally {
            setLoading(false);
        }
    };

    const downloadDocumentForId = async (id: number, fileName: string): Promise<void> => {
        setLoading(true);
        setError(null);

        try {
            const token = localStorage.getItem(accessTokenKey);

            const xhr = new XMLHttpRequest();
            xhr.open('GET', `${BASE_URL}/documents/${id}/download`, true);
            xhr.responseType = 'blob';
            if (token) {
                xhr.setRequestHeader('Authorization', `Bearer ${token}`);
            }

            xhr.onload = () => {
                if (xhr.status === 200) {
                    const blob = new Blob([xhr.response]);
                    const url = window.URL.createObjectURL(blob);
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', fileName);
                    document.body.appendChild(link);
                    link.click();
                    link.remove();
                    window.URL.revokeObjectURL(url);
                } else {
                    setError(`Error ${xhr.status}: ${xhr.statusText}`);
                }
            };

            xhr.onerror = () => {
                setError('Błąd połączenia z serwerem.');
            };

            xhr.send();
        } catch (err) {
            setError('Wystąpił błąd podczas pobierania pliku.');
        } finally {
            setLoading(false);
        }
    };

    const uploadDocument = async (file: File, name: string, collectionId?: string): Promise<UploadDocumentResponse> => {
        const formData = new FormData();
        formData.append('file', file);
        formData.append('name', name);
        if (collectionId) {
            formData.append('collectionId', collectionId);
        }

        const response = await axios.post<UploadDocumentResponse>('/documents', formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });

        return response.data;
    };

    const attachDocumentToConversation = async (file: File, name: string, conversationId?: string): Promise<UploadDocumentResponse> => {
        const formData = new FormData();
        formData.append('file', file);
        formData.append('name', name);
        if (conversationId) {
            formData.append('conversationId', conversationId);
        }

        const response = await axios.post<UploadDocumentResponse>('/documents/conversation', formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });

        return response.data;
    };


    return {
        loading,
        error,
        sendMessage,
        fetchCategories,
        fetchDocumentsByCategoryId,
        downloadDocumentForId,
        uploadDocument,
        attachDocumentToConversation
    };
};
