import axios from 'axios';

export const accessTokenKey = 'accessToken'

axios.defaults.baseURL = process.env.REACT_APP_AI_ASSISTANT_BACKEND_URL;

axios.interceptors.request.use((config) => {
    const token = localStorage.getItem(accessTokenKey);
    if (token) {
        config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
});

export default axios;
