import React, {useEffect, useState} from 'react';
import {IconButton, InputAdornment, Typography,} from '@mui/material';
import {Visibility, VisibilityOff} from '@mui/icons-material';
import {useAuth} from '../core/AuthContext';
import {useNavigate} from 'react-router-dom';
import {FormWrapper, LoginCard, LoginContainer, LogoWrapper, StyledButton, StyledTextField,} from './Login.styled';
import AsystentLogo from 'assets/images/asystent_it_logo.png';

const Login: React.FC = () => {
    const {isAuthenticated, login} = useAuth();
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        if (isAuthenticated) {
            navigate('/chat', {replace: true});
        }
    }, [isAuthenticated, navigate]);

    const handleLogin = () => {
        login(username, password);
    };

    const handleRegistration = () => {
        navigate('/register', {replace: true});
    };

    const togglePasswordVisibility = () => {
        setShowPassword((prev) => !prev);
    };

    return (
        <LoginContainer>
            <LoginCard>
                <LogoWrapper>
                    <img src={AsystentLogo} alt="Asystent IT Logo"/>
                </LogoWrapper>
                <Typography variant="h4" gutterBottom align="center">
                    Logowanie
                </Typography>
                <FormWrapper>
                    <StyledTextField
                        label="Nazwa Użytkownika"
                        fullWidth
                        margin="normal"
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                    />
                    <StyledTextField
                        label="Hasło"
                        type={showPassword ? 'text' : 'password'}
                        fullWidth
                        margin="normal"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        variant="outlined"
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton onClick={togglePasswordVisibility} edge="end">
                                        {showPassword ? <VisibilityOff/> : <Visibility/>}
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />
                    <StyledButton variant="contained" onClick={handleLogin}>
                        Zaloguj się
                    </StyledButton>
                    <StyledButton variant="outlined" onClick={handleRegistration}>
                        Zarejestruj się
                    </StyledButton>
                </FormWrapper>
            </LoginCard>
        </LoginContainer>
    );
};

export default Login;
