export interface Message {
    id: number;
    name: string;
    content: string;
}

export interface Category {
    id: number;
    name: string;
    disabled: boolean;
}

export interface PdfDocument {
    id: number;
    name: string;
    categoryName: string
}

export interface UploadDocumentResponse {
    documentId: number
}

export enum UserRole {
    SYSTEM_ADMIN = "SYSTEM_ADMIN",
    USER = "USER"
}

export interface User {
    userId?: number;
    id?: number;
    email: string;
    createdAt: string;
    role: UserRole;
    activated: boolean;
}
