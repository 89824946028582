import React from 'react';
import {Message} from "../pages/workspace/Chat";
import ImagePreview from "../components/common/ImagePreview";
import DownloadButton from "../components/common/DownloadButton";

export const formatMessageContent = (
    content: string,
    downloadDocumentForId: (documentId: number, fileName: string) => Promise<void>
) => {
    if (typeof content === 'string') {
        let cleanedContent = handleStreamingFormat(content);
        cleanedContent = cleanedContent
            .replace(/(<\/a>),/g, '$1')
            .replace(/(<\/a>)./g, '$1')
            .replace(/<\/img>/g, '');

        cleanedContent = handleNewLines(cleanedContent);
        cleanedContent = processCodeBlocks(cleanedContent);

        const processedText = [];
        let currentText = '';
        let i = 0;

        while (i < cleanedContent.length) {
            if (cleanedContent.substring(i, i + 4) === '<br>') {
                if (currentText) {
                    processedText.push(currentText);
                    currentText = '';
                }
                processedText.push('<br>');
                i += 4;
                continue;
            }

            if (cleanedContent.substring(i, i + 5) === '<img ') {
                const endIndex = cleanedContent.indexOf('>', i);
                if (endIndex !== -1) {
                    if (currentText) {
                        processedText.push(currentText);
                        currentText = '';
                    }
                    processedText.push(cleanedContent.substring(i, endIndex + 1));
                    i = endIndex + 1;
                    continue;
                }
            }

            if (cleanedContent.substring(i, i + 3) === '<a ') {
                const endIndex = cleanedContent.indexOf('</a>', i);
                if (endIndex !== -1) {
                    if (currentText) {
                        processedText.push(currentText);
                        currentText = '';
                    }
                    processedText.push(cleanedContent.substring(i, endIndex + 4));
                    i = endIndex + 4;
                    continue;
                }
            }

            currentText += cleanedContent[i];
            i++;
        }

        if (currentText) {
            processedText.push(currentText);
        }

        const formattedContent = processedText.map((chunk, index) => {
            if (chunk === '<br>') {
                return React.createElement('br', {key: index});
            } else if (chunk.startsWith('<img ')) {
                const src = chunk.match(/src='([^']+)'/)?.[1];
                if (src) {
                    return React.createElement(ImagePreview, {src, key: index});
                }
            } else if (chunk.startsWith('<a')) {
                const hrefMatch = chunk.match(/<a\s+href=['"]([^'"]+)['"]\s*>([^<]+)<\/a>/);

                if (hrefMatch) {
                    const url = hrefMatch[1].trim();
                    const fileName = hrefMatch[2].trim();

                    const documentId = parseInt(url.split('/').slice(-2, -1)[0], 10);

                    if (!isNaN(documentId)) {
                        return React.createElement(DownloadButton, {
                            key: index,
                            documentId,
                            fileName,
                            onDownload: downloadDocumentForId
                        });
                    }
                }
            }

            return chunk;
        });

        return formattedContent.filter(chunk => chunk !== '');
    }

    return content;
};

function handleStreamingFormat(text: string): string {
    const lines = text.split('\n');
    let result = '';

    for (let i = 0; i < lines.length; i++) {
        const line = lines[i].trim();

        if (line.startsWith('data:')) {
            const content = line.substring(5).trim();
            if (content === '') {
                result += '<br>';
            } else {
                result += content + ' ';
            }
        } else {
            result += line + ' ';
        }
    }

    return result;
}

function handleNewLines(text: string): string {
    let result = text.replace(/\\n/g, '<br>').replace(/\n/g, '<br>');

    result = result.replace(/(\s)(\d+\.\s)/g, '$1<br>$2');

    return result;
}

function processCodeBlocks(text: string): string {
    let result = text;
    const codeBlockRegex = /```(.*?)```/g;

    result = result.replace(codeBlockRegex, (match, codeContent) => {
        if (codeContent.match(/\.(jpg|jpeg|png|gif|bmp|webp|svg)$/i)) {
            return `<img src='${codeContent}'>`;
        }

        if (codeContent.startsWith('http')) {
            const fileName = codeContent.split('/').pop() || 'file';
            return `<a href='${codeContent}'>${fileName}</a>`;
        }

        return `<pre>${codeContent}</pre>`;
    });

    return result;
}

export const responseHandler = (
    text: string,
    // eslint-disable-next-line no-undef
    setMessages: React.Dispatch<React.SetStateAction<Message[]>>,
    downloadDocumentForId: (documentId: number, fileName: string) => Promise<void>
) => {
    setMessages((prev) => {
        const updatedMessages = [...prev];

        const formattedText = formatMessageContent(text, downloadDocumentForId);

        updatedMessages[updatedMessages.length - 1] = {
            author: 'Asystent IT',
            text: formattedText,
            isSystem: true,
            isLoading: false,
        };

        return updatedMessages;
    });
};
