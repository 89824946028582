import React, { useState } from 'react';
import axios from 'axios';
import {
    StyledDialog,
    StyledDialogTitle,
    StyledDialogContent,
    StyledDialogActions,
    InputField,
    ErrorText
} from './CreateCollectionDialog.styled';
import { Button, CircularProgress } from '@mui/material';

interface Props {
    open: boolean;
    onClose: () => void;
    onCollectionCreated: () => void;
}

const CreateCollectionDialog: React.FC<Props> = ({ open, onClose, onCollectionCreated }) => {
    const [collectionName, setCollectionName] = useState('');
    const [error, setError] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);

    const handleSubmit = async () => {
        if (!collectionName.trim()) {
            setError('Collection name is required');
            return;
        }

        try {
            setIsSubmitting(true);
            const response = await axios.post('/documents/by-collection', {collectionName});
            onCollectionCreated();
            setCollectionName(collectionName);
            onClose();
        } catch (error) {
            setError('Failed to create collection');
        } finally {
            setIsSubmitting(false);
        }
    };

    return (
        <StyledDialog open={open} onClose={onClose}>
            <StyledDialogTitle>Utwórz nową kolekcje</StyledDialogTitle>
            <StyledDialogContent>
                <InputField
                    label="Nazwa kolekcji"
                    variant="outlined"
                    fullWidth
                    value={collectionName}
                    onChange={(e) => {
                        setCollectionName(e.target.value);
                        setError('');
                    }}
                    error={!!error}
                />
                {error && <ErrorText>{error}</ErrorText>}
            </StyledDialogContent>
            <StyledDialogActions>
                <Button onClick={onClose} disabled={isSubmitting}>
                    Cancel
                </Button>
                <Button
                    onClick={handleSubmit}
                    color="primary"
                    variant="contained"
                    disabled={isSubmitting}
                >
                    {isSubmitting ? <CircularProgress size={24} /> : 'Create'}
                </Button>
            </StyledDialogActions>
        </StyledDialog>
    );
};

export default CreateCollectionDialog;