import styled from "styled-components";
import {Button} from "@mui/material";

export const StyledTableCard = styled.div`
    background: white;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    overflow: hidden;
`;

export const StyledTable = styled.table`
    width: 100%;
    border-collapse: collapse;

    thead {
        font-weight: bold;
        font-size: 18px;
    }
`;

export const StyledTableRow = styled.tr`
    &:nth-child(even) {
        background-color: #f5f5f5;
    }
`;

export const StyledTableCell = styled.td`
    padding: 1rem;
    border-bottom: 1px solid #ddd;
`;

export const ActionButton = styled(Button)`
    margin: 4px !important;
    text-transform: none !important;
`;
