import React, {useEffect, useState} from "react";
import {Header, StyledContainer} from "./UserList.styled";
import styled from "styled-components";
import {Button, FormControl, InputLabel, MenuItem, Select, TextField,} from "@mui/material";
import {User, UserRole} from "../../models/models";
import axios from "axios";
import {useLocation, useNavigate} from "react-router-dom";

const UserEditWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 100%;
    max-width: 715px;
    margin: 20px auto;
`;

const ButtonGroup = styled.div`
    display: flex;
    justify-content: flex-end;
    gap: 10px;
`;

const UserEdit: React.FC = () => {
    const [user, setUser] = useState<User | null>(null);
    const [password, setPassword] = useState<string>('');
    const navigate = useNavigate();
    const location = useLocation();

    const queryParams = new URLSearchParams(location.search);
    const activated = queryParams.get("activated");
    const role = queryParams.get("role");
    const email = queryParams.get("email");
    const createdAt = queryParams.get("createdAt");

    useEffect(() => {
        if (!activated || !role || !email || !createdAt) {
            navigate(-1);
        } else {
            setUser({
                email,
                createdAt,
                role: role as UserRole,
                activated: Boolean(activated)
            });
        }
    }, [activated, role, email, createdAt, navigate]);

    const handleInputChange = (field: keyof User, value: any) => {
        setUser((prev) => prev && {...prev, [field]: value});
    };

    const handleSubmit = async () => {
        try {
            await axios.patch(`/users?userEmail=${user?.email || ""}`, {
                newPassword: password,
                role: user?.role,
                isActive: user?.activated,
            });
            alert("Użytkownik został zaktualizowany!");
        } catch (error) {
            alert("Wystąpił błąd podczas aktualizacji użytkownika.");
        }
    };

    if (!user) {
        return null;
    }

    return (
        <StyledContainer>
            <Header>
                <h2>Modyfikacja Użytkownika</h2>
            </Header>
            <UserEditWrapper>
                <TextField
                    label="Email"
                    value={user.email}
                    disabled
                    fullWidth
                />
                <TextField
                    label="Data utworzenia"
                    value={user.createdAt}
                    disabled
                    fullWidth
                />
                <FormControl fullWidth>
                    <InputLabel>Rola</InputLabel>
                    <Select
                        value={user.role}
                        onChange={(e) => handleInputChange("role", e.target.value)}
                    >
                        <MenuItem value={UserRole.SYSTEM_ADMIN}>Administrator</MenuItem>
                        <MenuItem value={UserRole.USER}>Użytkownik</MenuItem>
                    </Select>
                </FormControl>
                <FormControl fullWidth>
                    <InputLabel>Status</InputLabel>
                    <Select
                        value={user.activated.toString()}
                        onChange={(e) =>
                            handleInputChange("activated", e.target.value === "true")
                        }
                    >
                        <MenuItem value="true">Aktywny</MenuItem>
                        <MenuItem value="false">Nieaktywny</MenuItem>
                    </Select>
                </FormControl>
                <FormControl fullWidth>
                    <TextField
                        label="Nowe Hasło"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        fullWidth
                    />
                </FormControl>
                <ButtonGroup>
                    <Button variant="contained" color="primary" onClick={handleSubmit}>
                        Zapisz
                    </Button>
                    <Button
                        variant="outlined"
                        color="secondary"
                        onClick={() => navigate(-1)}
                    >
                        Anuluj
                    </Button>
                </ButtonGroup>
            </UserEditWrapper>
        </StyledContainer>
    )
        ;
};

export default UserEdit;
