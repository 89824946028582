import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    justify-content: center;
    padding: 20px;
`;

export const Card = styled.div`
    width: 650px;
    padding: 42px;
    border-radius: 16px;
    background: #ffffff;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    border: 1px solid #e5edf3;
`;

export const CloseButtonWrapper = styled.div`
    display: flex;
    justify-content: flex-end;
    width: 100%;
`

export const CloseButton = styled.button`

    background: none;
    border: none;
    cursor: pointer;
`;

export const LogoWrapper = styled.div`
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
`;

export const LogoImage = styled.img`
    width: 80px;
    height: auto;
`;

export const Header = styled.h2`
    text-align: center;
    margin-bottom: 20px;
`;

export const SelectWrapper = styled.div`
    margin-bottom: 20px;

    select {
        width: 100%;
        padding: 10px;
        border: 1px solid #dde2e6;
        border-radius: 8px;
        font-size: 16px;
    }
`;

export const DocumentsContainer = styled.div`
    margin-top: 36px;
    text-align: center;

    h2 {
        margin-bottom: 32px;
    }
`;

export const Table = styled.table`
    width: 100%;
    border-collapse: collapse;
    border: 1px solid #dde2e6;

    th,
    td {
        padding: 12px;
        text-align: center;
        border-bottom: 1px solid #dde2e6;
    }
`;

export const TableHeader = styled.tr`
    background: #f5f5f5;
    font-weight: bold;
`;

export const TableRow = styled.tr`
    &:hover {
        background: #f9f9f9;
        cursor: pointer;
    }
`;

export const TableCell = styled.td`
    padding: 12px;
`;

export const DownloadButton = styled.button`
    background: none;
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    margin: auto;
`;

export const NoCategorySelected = styled.p`
    text-align: center;
    font-size: 14px;
    color: #888;
    margin-top: 20px;
`;

export const AddButton = styled.button`
    background-color: #4CAF50;
    color: white;
    padding: 10px 16px;
    margin-bottom: 20px;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    display: flex;
    align-items: center;
    font-size: 16px;

    &:hover {
        background-color: #45a049;
    }

    svg {
        margin-right: 8px;
    }
`;

export const ModalOverlay = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
`;

export const ModalContent = styled.div`
    background: white;
    padding: 20px;
    border-radius: 8px;
    width: 400px;
    box-shadow: 0 5px 15px rgba(0,0,0,0.3);
`;

export const ModalHeader = styled.h3`
    margin-bottom: 20px;
    text-align: center;
`;

export const ModalBody = styled.div`
    display: flex;
    flex-direction: column;
    gap: 15px;
`;

export const ModalFooter = styled.div`
    display: flex;
    justify-content: flex-end;
    gap: 10px;
    margin-top: 20px;
`;

export const Input = styled.input`
    padding: 10px;
    border: 1px solid #dde2e6;
    border-radius: 8px;
    font-size: 16px;
    width: 100%;
`;

export const SubmitButton = styled.button`
    background-color: #4CAF50;
    color: white;
    padding: 10px 16px;
    border: none;
    border-radius: 8px;
    cursor: pointer;

    &:hover {
        background-color: #45a049;
    }
`;

export const CancelButton = styled.button`
    background-color: #f44336;
    color: white;
    padding: 10px 16px;
    border: none;
    border-radius: 8px;
    cursor: pointer;

    &:hover {
        background-color: #d32f2f;
    }
`;
