import React, {createContext, ReactNode, useEffect, useState} from 'react';

interface ContextProps {
    entities: any[];
    loading: boolean;
    getEntityById: (id: number) => any | undefined;
}

const Context = createContext<ContextProps | undefined>(undefined);

export const Provider: React.FC<{ children: ReactNode }> = ({children}) => {
    const [entities, setEntities] = useState<any[]>([]);
    const [loading, setLoading] = useState<boolean>(true);

    useEffect(() => {
        const fetchData = async () => {
            try {
                setEntities([]);
            } catch (error) {
                console.error('Error loading data:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, []);

    const getEntityById = (id: number): any | undefined => {
        return entities.find(entity => entity.id === id);
    };

    return (
        <Context.Provider value={{entities, loading, getEntityById}}>
            {children}
        </Context.Provider>
    );
};

export const useContext = () => {
    const context = React.useContext(Context);
    if (context === undefined) {
        throw new Error('useContext must be used within a Provider');
    }
    return context;
};
