import React from 'react';
import styled from 'styled-components';

import NotificationIcon from '../../assets/icons/bell.svg';
import HelpIcon from '../../assets/icons/help-circle-gray.svg';

const NavBarContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding: 36px;
    padding-bottom: 0;
`;

const ActionsContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: flex-end;
    gap: 12px;
    margin-bottom: 16px;

    img {
        cursor: pointer;
        height: 24px;
        width: 24px;
    }
`;

const Line = styled.div`
    background: #f0f0f0;
    height: 1px;
    width: 100%;
`;

const NavBar = () => {
    return (
        <NavBarContainer>
            <ActionsContainer>
                <img src={NotificationIcon} alt="Notification"/>
                <img src={HelpIcon} alt="Help"/>
            </ActionsContainer>
            <Line/>
        </NavBarContainer>
    );
};

export default NavBar;
