declare global {
    interface Window {
        webkitSpeechRecognition: any;
    }
}

import React, {useState} from 'react';
import {
    Container,
    FileInfoInsideInput,
    InputActions,
    MicrophoneButton,
    RemoveButton,
    SendButtonWrapper,
    StyledForm,
    StyledTextField
} from './ChatInput.styled';
import {IconButton} from '@mui/material';
import AttachmentIcon from '@mui/icons-material/AttachFile';
import MicIcon from '@mui/icons-material/Mic';
import SendSvg from '../../assets/icons/arrow-narrow-up.svg';


interface ChatInputProps {
    onSendMessage: (text: string) => void;
    onFileChange: (file: File | null) => void;
}

const ChatInput: React.FC<ChatInputProps> = ({onSendMessage, onFileChange}) => {
    const [text, setText] = useState('');
    const [isRecording, setIsRecording] = useState(false);
    const [attachedFile, setAttachedFile] = useState<File | null>(null);

    const handleSend = () => {
        if (text.trim()) {
            onSendMessage(text);
            setText('');
            handleRemoveFile()
        }
    };

    const handleKeyDown = (event: any) => {
        if (event.key === 'Enter' && !event.shiftKey) {
            event.preventDefault();
            handleSend();
        }
    };

    const handleVoiceRecognition = () => {
        if (!('webkitSpeechRecognition' in window)) {
            alert('Twoja przeglądarka nie wspiera rozpoznawania mowy.');
            return;
        }

        const recognition = new window.webkitSpeechRecognition();
        recognition.lang = 'pl-PL';
        recognition.continuous = true;
        recognition.interimResults = false;

        let idleTimeout: any;

        const resetIdleTimeout = () => {
            clearTimeout(idleTimeout);
            idleTimeout = setTimeout(() => {
                recognition.stop();
            }, 3000);
        };

        recognition.onstart = () => {
            setIsRecording(true);
            resetIdleTimeout();
        };

        recognition.onend = () => {
            setIsRecording(false);
            clearTimeout(idleTimeout);
        };

        recognition.onresult = (event: any) => {
            const transcript = Array.from(event.results as any[])
                .map((result) => result[0].transcript)
                .join(' ');
            setText((prevText) => `${transcript}`.trim());
            resetIdleTimeout();
        };

        recognition.onerror = (event: any) => {
            console.error('Błąd rozpoznawania mowy:', event.error);
            setIsRecording(false);
            clearTimeout(idleTimeout);
        };

        recognition.start();
    };

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (file && (file.type === 'text/plain' || file.type === 'application/pdf')) {
            setAttachedFile(file);
            onFileChange(file)
        } else {
            alert('Tylko pliki .txt lub .pdf są obsługiwane.');
        }
    };
    const handleRemoveFile = () => {
        setAttachedFile(null);
        onFileChange(null)
    };
    return (
        <Container>
            <StyledForm>
                <InputActions $attachedFile={!!attachedFile}>
                    <label htmlFor="file-input"
                           style={{pointerEvents: attachedFile ? 'none' : 'auto', opacity: attachedFile ? '0.5' : '1'}}>
                        <input
                            id="file-input"
                            type="file"
                            style={{display: 'none'}}
                            onChange={handleFileChange}
                        />
                        <IconButton component="span">
                            <AttachmentIcon/>
                        </IconButton>
                    </label>
                    <MicrophoneButton onClick={handleVoiceRecognition} $isRecording={isRecording}>
                        <MicIcon/>
                    </MicrophoneButton>
                </InputActions>
                {/*<StyledTextField*/}
                {/*    value={text}*/}
                {/*    onKeyDown={handleKeyDown}*/}
                {/*    onChange={(e) => setText(e.target.value)}*/}
                {/*    fullWidth*/}
                {/*    placeholder="Podaj kolejne pytanie ..."*/}
                {/*    variant="outlined"*/}
                {/*    multiline*/}
                {/*    rows={1}*/}
                {/*/>*/}

                <StyledTextField
                    value={text}
                    onKeyDown={handleKeyDown}
                    onChange={(e) => setText(e.target.value)}
                    fullWidth
                    placeholder="Podaj kolejne pytanie ..."
                    variant="outlined"
                    multiline
                    rows={1}
                    $hasFile={Boolean(attachedFile)}
                    InputProps={{
                        startAdornment: attachedFile && (
                            <FileInfoInsideInput>
                                <span>{attachedFile.name}</span>
                                <RemoveButton onClick={handleRemoveFile}/>
                            </FileInfoInsideInput>
                        ),
                    }}
                />
                <SendButtonWrapper>
                    <IconButton disabled={!text} onClick={handleSend}>
                        <img src={SendSvg} alt="Send"/>
                    </IconButton>
                </SendButtonWrapper>

            </StyledForm>
            {/*{attachedFile && (*/}
            {/*    <FileInfoWrapper>*/}
            {/*        <span>{attachedFile.name}</span>*/}
            {/*        <RemoveButton onClick={handleRemoveFile}></RemoveButton>*/}
            {/*    </FileInfoWrapper>*/}
            {/*)}*/}
        </Container>
    );
};

export default ChatInput;
