import React, {useEffect, useRef, useState} from 'react';
import {
    DocumentAttachment,
    DocumentInfo,
    DocumentName,
    Dot,
    ErrorMessageSystemText,
    LoadingDots,
    MessageRate,
    MessagesContainer,
    MessageSystem,
    MessageSystemAuthor,
    MessageSystemHeader,
    MessageSystemHeaderIcons,
    MessageSystemText,
    MessageYou,
    MessageYouAuthor,
    MessageYouText,
    StyledButton,
    StyledDownloadButton,
} from './ChatMessages.styled';
import DownloadIcon from '@mui/icons-material/Download';
import {Tooltip} from "@mui/material";
import PlusIcon from '../../assets/icons/plus.svg';
import MinusIcon from '../../assets/icons/minus.svg';
import AsystentAvatar from '../../assets/images/asystent_it_avatar.png';
import YouAvatar from '../../assets/images/avatar.png';
import CopySvg from "../../assets/icons/copy.svg";
import MoreSvg from "../../assets/icons/more.svg";
import {Message} from "../../pages/workspace/Chat";
import {useApi} from "../../hooks/UseApi";

const ChatMessages = ({messages}: { messages?: Message[] }) => {
    const {downloadDocumentForId} = useApi();
    const containerRef = useRef<HTMLDivElement>(null);
    const [isFirstUpdate, setIsFirstUpdate] = useState(true);
    const copyToClipboard = (text: string) => {
        void navigator.clipboard.writeText(text)
    };
    useEffect(() => {
        console.log("messages: ", messages);
        setTimeout(() => {
            if (containerRef.current) {
                containerRef.current.scrollTo({
                    top: containerRef.current.scrollHeight,
                    behavior: isFirstUpdate ? 'auto' : 'smooth'
                });

                if (isFirstUpdate) {
                    setIsFirstUpdate(false);
                }
            }
        }, 120)
    }, [messages, isFirstUpdate]);
    if (messages?.length === 0) {
        return null;
    }

    return (
        <MessagesContainer ref={containerRef}>
            {messages?.map((message, index) =>
                message.isSystem ? (
                    <MessageSystem key={index}>
                        <MessageSystemHeader>
                            <MessageSystemAuthor>
                                <img src={AsystentAvatar} alt="Asystent IT"/>
                                {message.author}
                            </MessageSystemAuthor>
                            {!message.isLoading && !message.isError && (
                                <MessageSystemHeaderIcons>
                                    <StyledButton onClick={() => copyToClipboard(message.text)}>
                                        <img src={CopySvg} alt="Copy"/>
                                    </StyledButton>
                                    <StyledButton>
                                        <img src={MoreSvg} alt="More"/>
                                    </StyledButton>
                                </MessageSystemHeaderIcons>
                            )}
                        </MessageSystemHeader>
                        {message.isLoading ? (
                            <LoadingDots>
                                <Dot/>
                                <Dot/>
                                <Dot/>
                            </LoadingDots>
                        ) : message.isError ? (
                            <ErrorMessageSystemText>{message.text}</ErrorMessageSystemText>
                        ) : (
                            <MessageSystemText>{message.text}</MessageSystemText>
                        )}
                        {!message.isLoading && !message.isError && (
                            <MessageRate>
                                <span>Oceń odpowiedź</span>
                                <img src={PlusIcon} alt="Rate up"/>
                                <img src={MinusIcon} alt="Rate down"/>
                            </MessageRate>
                        )}
                    </MessageSystem>
                ) : (
                    <div key={index} style={{marginBottom: '32px'}}>
                        {message.documentName && (
                            <DocumentAttachment>
                                <DocumentInfo>
                                    <DocumentName>{message.documentName}</DocumentName>
                                </DocumentInfo>
                                {message.documentId && message.documentId !== -1
                                    && (
                                        <Tooltip title="Pobierz plik" arrow placement="top">
                                            <StyledDownloadButton
                                                variant="text"
                                                color="primary"
                                                size="small"
                                                startIcon={<DownloadIcon/>}
                                                onClick={() => downloadDocumentForId(message.documentId!, message.documentName!)}
                                            ></StyledDownloadButton>
                                        </Tooltip>
                                    )
                                }
                            </DocumentAttachment>

                        )}
                        <MessageYou>
                            <MessageYouAuthor>
                                <img src={YouAvatar} alt="User Avatar"/>
                                {message.author && <span>{message.author}</span>}
                            </MessageYouAuthor>
                            <MessageYouText>{message.text}</MessageYouText>
                        </MessageYou>
                    </div>
                )
            )}
        </MessagesContainer>
    );
};

export default ChatMessages;
