import styled, {keyframes} from 'styled-components';

export const MessagesContainer = styled.div`
    flex: 1;
    overflow-y: auto;
    margin-bottom: 20px;
    position: relative;
    border-radius: 16px;
    border: 1px solid #dde2e6;
    padding: 20px 16px 16px;
    background: #ffffff;
`;

export const Message = styled.div`
    display: flex;
    position: relative;
    margin-bottom: 32px;

    &:last-child {
        margin-bottom: 0;
    }
`;

export const MessageYou = styled(Message)`
    position: relative;
    display: flex;
    align-items: center;
`;
export const MessageYouAuthor = styled.div`
    display: flex;
    position: absolute;
    width: fit-content;
    align-items: center;
    gap: 12px;
    border: 1px solid #C7C5C6;
    border-radius: 24px;
    padding: 4px 8px 4px 4px;
    font-weight: 600;
    font-size: 14px;
    color: #1f2021;
    background: #F0F0F0;
    z-index: 50;

    img {
        width: 35px;
        height: 35px;
    }
`;


export const MessageYouText = styled.div`
    font-weight: 600;
    background: #E0DDDE;
    height: fit-content;
    min-height: 44px;
    width: 100%;
    word-break: break-all;
    font-size: 14px;
    border-radius: 24px;
    padding: 8px 10px 8px 162px;
    display: flex;
    align-items: center;

    img {
        width: 35px;
        height: 35px;
    }
`;

export const MessageSystem = styled(Message)`
    flex-direction: column;
    align-items: flex-start;
`;

export const MessageSystemAuthor = styled.div`
    display: flex;
    align-items: center;
    gap: 12px;
    border: 1px solid #c7c5c6;
    border-radius: 24px;
    padding: 4px 8px;
    font-weight: 600;
    font-size: 14px;
    color: #1f2021;
    background: #f0f0f0;
    width: fit-content;
    margin-bottom: 8px;

    img {
        width: 24px;
        height: 24px;
    }
`;

export const MessageSystemText = styled.div`
    font-weight: 400;
    font-size: 14px;
    min-height: 44px;
    word-break: break-word;
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 100%;
`;

export const MessageRate = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 8px;
    margin-top: 8px;
    width: 100%;

    span {
        font-size: 12px;
        color: #D9D9D9;
    }

    img {
        cursor: pointer;
        width: 20px;
        height: 20px;
    }
`;

export const MessageSystemHeader = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
`


export const MessageSystemHeaderIcons = styled.div`
    display: flex;
    gap: 4px;
`


export const StyledButton = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    background: white;
    border: none;
    border-radius: 16px;
    width: 30px;
    padding: 5px;
    margin: 8px 0;
    cursor: pointer;
    transition: background 0.2s ease;
    font-size: 16px;

    &:hover {
        background: #f0f0f0;
    }
`;

const dotFlashing = keyframes`
    0% {
        background-color: #989898;
    }
    50%, 100% {
        background-color: rgba(122, 122, 122, 0.3);
    }
`;

export const LoadingDots = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    height: 20px;
    margin-bottom: 8px;
    width: 100%;
`;

export const Dot = styled.div`
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #989898;
    animation: ${dotFlashing} 1.5s infinite linear;

    &:nth-child(1) {
        animation-delay: 0s;
    }

    &:nth-child(2) {
        animation-delay: 0.3s;
    }

    &:nth-child(3) {
        animation-delay: 0.6s;
    }
`;

export const ErrorMessageSystemText = styled(MessageSystemText)`
    color: #ff4d4f;
    font-weight: 600;
`;
