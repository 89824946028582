import React from "react";
import {useNavigate} from "react-router-dom";
import {ButtonWrapper, Header, StyledButton, StyledContainer} from "./AdminPanel.styled";
import {ReactComponent as UsersIcon} from "assets/icons/users.svg";
import {ReactComponent as UserIcon} from "assets/icons/user.svg";

const AdminPanel: React.FC = () => {
    const navigate = useNavigate();

    return (
        <StyledContainer>
            <Header>
                <h2>Panel Administratora</h2>
            </Header>
            <ButtonWrapper>
                <StyledButton
                    variant="outlined"
                    onClick={() => navigate("/user/list")}
                    startIcon={<UserIcon/>}
                >
                    Użytkownicy
                </StyledButton>
                <StyledButton
                    variant="outlined"
                    onClick={() => navigate("/group/list")}
                    startIcon={<UsersIcon/>}
                >
                    Grupy
                </StyledButton>
            </ButtonWrapper>
        </StyledContainer>
    );
};

export default AdminPanel;
