import React, {createContext, ReactNode, useContext, useState} from 'react';
import axios from "axios";
import {accessTokenKey} from "./AuthInterceptor";
import {useOnMount} from "../hooks/UseOnMount";


interface AuthContextType {
    isAuthenticated: boolean;
    isVerifying: boolean;
    login: (email: string, password: string) => Promise<void>;
    logout: () => void;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const AuthProvider: React.FC<{ children: ReactNode }> = ({children}) => {
    const [isAuthenticated, setIsAuthenticated] = useState(() => {
        const token = localStorage.getItem('token');
        return !!token;
    });
    const [isVerifying, setIsVerifying] = useState(true);
    useOnMount(async () => {
        if (!localStorage.getItem(accessTokenKey)) {
            setIsVerifying(false);
            return;
        }
        try {
            const response = await axios.get(`/users/currentUserDetails`);
            if (response.status === 200) {
                setIsAuthenticated(true);
                setIsVerifying(false);
            }
        } catch (error) {
            localStorage.removeItem(accessTokenKey)
            window.location.href = 'login'
        }
    })

    const login = async (email: string, password: string) => {
        try {
            const response = await axios.post(`/auth/login`, {
                email,
                password,
            });

            if (response.status === 200) {
                setIsAuthenticated(true);
                localStorage.setItem(accessTokenKey, response.data.accessToken);
            }
        } catch (error) {
            console.error("Błąd logowania:", error);
            setIsAuthenticated(false);
            alert('Niepoprawne dane logowania');
        }
    };

    const logout = () => {
        localStorage.removeItem(accessTokenKey);
        setIsAuthenticated(false);
    };

    return (
        <AuthContext.Provider value={{isAuthenticated, login, logout, isVerifying}}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => {
    const context = useContext(AuthContext);
    if (!context) {
        throw new Error('useAuth must be used within an AuthProvider');
    }
    return context;
};
