import {createBrowserRouter, createRoutesFromElements, Route} from "react-router-dom";
import React from "react";
import Chat from "./pages/Chat";
import Layout from "./components/Layout";
import ManageDocuments from "./pages/ManageDocuments";
import PrivateRoute from "./components/PrivateRoute";
import Login from "./pages/Login";
import Register from "./pages/Register";

export const router = createBrowserRouter(
    createRoutesFromElements(
        <Route path="/">
            <Route path="/" element={<PrivateRoute/>}>
                <Route path="/" element={<Layout/>}>
                    <Route path="/chat" Component={Chat}/>
                    <Route path="/manage-documents" Component={ManageDocuments}/>
                </Route>
            </Route>
            <Route path="/register" element={<Register/>}/>
            <Route path="*" element={<Login/>}/>
        </Route>
    ), {
        future: {
            v7_relativeSplatPath: true,
            v7_normalizeFormMethod: true,
            v7_partialHydration: true,
            v7_fetcherPersist: true,
            v7_skipActionErrorRevalidation: true,
        },
    }
);
