import styled from 'styled-components';
import {Drawer, IconButton} from '@mui/material';

interface OpenProps {
    open: boolean;
}

const filterColor = `
  invert(32%) sepia(100%) saturate(500%) hue-rotate(190deg) brightness(95%) contrast(90%)
`;

export const SidebarContainer = styled(Drawer)`
    font-size: 17px;

    .MuiDrawer-paper {
        width: ${(props) => (props.open ? '280px' : '70px')};
        transition: width 0.3s;
        overflow-x: hidden;
        display: flex;
        flex-direction: column;
    }
`;

export const StyledIconButton = styled(IconButton)`
    img {
        filter: ${filterColor};
    }
`;
export const SidebarHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px;

`;

export const StyledList = styled.div<OpenProps>`
    padding: ${(props: any) => (props.open ? '16px' : '8px')};
`


export const LogoBox = styled.div`
    width: 44px;
    height: 44px;

    img {
        width: 44px;
        height: 44px;
    }
`;

export const StyledButton = styled.button`
    display: flex;
    align-items: center;
    width: 100%;
    background: white;
    border: none;
    border-radius: 40px;
    padding: 12px 16px;
    margin: 8px 0;
    cursor: pointer;
    transition: background 0.2s ease;
    font-size: 16px;

    &:hover {
        background: #f0f0f0;
    }

    img {
        margin-right: 12px;
        filter: ${filterColor};
    }

    span {
    }
`;

export const StyledReportProblemWrapper = styled.div<OpenProps>`
    padding: ${(props: any) => (props.open ? '16px' : '8px')};
    margin-top: auto;
`

export const StyledReportProblemButton = styled(StyledButton)`
    background: #f0f0f0;

    &:hover {
        background: #e6e6e6;
    }
`

export const StyledNewRequestButton = styled(StyledButton)`
    background: #f0f0f0;
    border-radius: 0 40px 40px 0;
    padding: 10px 12px;

    &:hover {
        background: #e6e6e6;
    }
`

export const Divider = styled.div`
    height: 1px;
    width: 100%;
    background: #f0f0f0;
    margin: 16px 0;
`;

export const ProfileContainer = styled.div`
    display: flex;
    align-items: center;
    //justify-content: space-between;
    padding: 12px;

    img {
        margin-right: 12px;
        border-radius: 50%;
    }

    span {
        font-weight: 600;
    }
`;

export const ProfileMoreBtn = styled(IconButton)`
    margin-left: auto !important;

    img {
        margin: 0;
        width: 18px;
        height: 18px;
    }
`
