import React, {useEffect, useState} from 'react';
import {List, ListItem, Menu, MenuItem} from '@mui/material';
import axios from 'axios';

import MenuSvg from "../../assets/icons/menu.svg";
import EditSvg from "../../assets/icons/edit.svg";
import ChevronRightSvg from "../../assets/icons/chevron-right.svg";
import FileQuestionSvg from "../../assets/icons/file-question.svg";
import HelpCircleSvg from "../../assets/icons/help-circle.svg";
import MessageCheckSvg from "../../assets/icons/message-check-square.svg";
import AlertTriangleSvg from "../../assets/icons/alert-triangle.svg";
import TrashIcon from '../../assets/icons/trash.svg'
import MoreSvg from "../../assets/icons/more.svg";
import LogoPng from "../../assets/images/logo.png";
import AvatarPng from "../../assets/images/avatar.png";
import {
    ConversationList,
    DeleteButton,
    Divider,
    LogoBox,
    ProfileContainer,
    ProfileMoreBtn,
    SidebarContainer,
    SidebarHeader,
    StyledButton,
    StyledIconButton,
    StyledList,
    StyledNewRequestButton,
    StyledNewRequestButtonContent,
    StyledReportProblemButton,
    StyledReportProblemWrapper,
    StyledSectionHeader
} from './Sidebar.styled';
import {useUser} from "../../core/UserContext";
import {useAuth} from "../../core/AuthContext";
import {useLocation, useNavigate} from "react-router-dom";
import AdminDropdown from "../admin/AdminDropdown";
import {ConversationResponse} from '../../models/models';

const Sidebar = ({isOpen, onToggle}: { isOpen: boolean; onToggle: () => void }) => {
    const {user, fetchUserData, refreshConversations} = useUser();
    const {logout} = useAuth()
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [isSystemAdmin, setIsSystemAdmin] = useState<boolean>(false);
    const [conversations, setConversations] = useState<ConversationResponse[]>([]);
    const [editingId, setEditingId] = useState<number | null>(null);
    const [newTitle, setNewTitle] = useState('');
    const navigate = useNavigate()
    const location = useLocation();
    const isActive = location.pathname.includes("/chat");

    useEffect(() => {
        if (!user) {
            void fetchUserData();
            return
        }
        setIsSystemAdmin(user.role === 'SYSTEM_ADMIN')
    }, [user]);

    useEffect(() => {
        if (isOpen) {
            void fetchConversations();
        }
    }, [isOpen, refreshConversations])

    const fetchConversations = async () => {
        try {
            const response = await axios.get('/chat/conversation/history');
            setConversations(response.data);
        } catch (error) {
            console.error('Error fetching conversations:', error);
        }
    };

    const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const handleLogoutClick = () => {
        handleMenuClose();
        logout();
    };

    const handleNewConversation = async () => {
        try {
            navigate(`/chat`);
        } catch (error) {
            console.error('Error creating conversation:', error);
        }
    }

    const handleTitleUpdate = async (convoId: number) => {
        try {
            await axios.patch(`/chat/conversation/${convoId}/title?title=${encodeURIComponent(newTitle)}`);
            setConversations(prev =>
                prev.map(convo =>
                    convo.id === convoId ? {...convo, title: newTitle} : convo
                )
            );
            setEditingId(null);
            setNewTitle('');
        } catch (error) {
            console.error('Error updating title:', error);
        }
    }

    const handleDeleteConversation = async (conversationId: number) => {
        try {
            await axios.delete(`/chat/conversation/${conversationId}`);
            await fetchConversations();
        } catch (error) {
            console.error('Error deleting conversation:', error);
        }
    };

    const groupConversationsByDate = (conversations: ConversationResponse[]) => {
        const now = new Date();
        const groups: { [key: string]: ConversationResponse[] } = {};

        conversations.forEach(convo => {
            const convoDate = new Date(convo.createdAt);
            const diffDays = Math.floor((now.getTime() - convoDate.getTime()) / (1000 * 60 * 60 * 24));

            if (diffDays === 0) {
                (groups.Dzisiaj ??= []).push(convo);
            } else if (diffDays === 1) {
                (groups.Wczoraj ??= []).push(convo);
            } else if (diffDays <= 7) {
                (groups['Ostatnie 7 dni'] ??= []).push(convo);
            } else {
                (groups.Starsze ??= []).push(convo);
            }
        });

        return groups;
    };

    return (
        <SidebarContainer variant="permanent" open={isOpen}>
            <SidebarHeader>
                <LogoBox>
                    {isOpen && <img src={LogoPng} alt="Logo"/>}
                </LogoBox>
                <StyledIconButton onClick={onToggle}>
                    <img src={MenuSvg} alt="Menu"/>
                </StyledIconButton>
            </SidebarHeader>
            <StyledList open={isOpen}>
                <List>
                    <ListItem disablePadding>
                        <StyledNewRequestButton $active={isActive} onClick={handleNewConversation}>
                            <StyledNewRequestButtonContent>
                                <img src={EditSvg} alt="Edit"/>
                                {isOpen && <span>Nowe zapytanie</span>}
                                {isOpen && <img src={ChevronRightSvg} alt="Arrow"/>}
                            </StyledNewRequestButtonContent>
                        </StyledNewRequestButton>
                    </ListItem>
                    {isSystemAdmin && <AdminDropdown isOpen={isOpen}/>}

                    <Divider/>
                    <StyledButton>
                        <img src={FileQuestionSvg} alt="File Question"/>
                        {isOpen && <span>Materiały Szkoleniowe</span>}
                    </StyledButton>
                    <StyledButton>
                        <img src={MessageCheckSvg} alt="Message Check"/>
                        {isOpen && <span>Zgłoszone sprawy</span>}
                    </StyledButton>
                    <StyledButton>
                        <img src={HelpCircleSvg} alt="Help"/>
                        {isOpen && <span>Poproś o wsparcie</span>}
                    </StyledButton>

                    <Divider/>
                    <ConversationList>
                        {isOpen && Object.entries(groupConversationsByDate(conversations)).map(([period, periodConversations]) => (
                            <div key={period}>
                                <StyledSectionHeader>{period}</StyledSectionHeader>
                                {periodConversations.map(convo => (
                                    <ListItem key={convo.id} disablePadding>
                                        <StyledButton
                                            onClick={() => navigate(`/chat/${convo.id}`)}
                                            onDoubleClick={() => {
                                                setEditingId(convo.id);
                                                setNewTitle(convo.title || `Rozmowa ${convo.id}`);
                                            }}
                                        >
                                            <img src={MessageCheckSvg} alt="Conversation"/>
                                            {editingId === convo.id ? (
                                                <input
                                                    type="text"
                                                    value={newTitle}
                                                    onChange={(e) => setNewTitle(e.target.value)}
                                                    onBlur={() => handleTitleUpdate(convo.id)}
                                                    onKeyPress={(e) => e.key === 'Enter' && handleTitleUpdate(convo.id)}
                                                    onClick={(e) => e.stopPropagation()}
                                                    style={{
                                                        border: 'none',
                                                        background: 'transparent',
                                                        color: 'inherit',
                                                        font: 'inherit',
                                                        width: '100%'
                                                    }}
                                                />
                                            ) : (
                                                convo.title || `Rozmowa ${convo.id}`
                                            )}
                                        </StyledButton>
                                        <DeleteButton
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                handleDeleteConversation(convo.id);
                                            }}
                                        >
                                            <img src={TrashIcon} alt="Delete" width={16} height={16}/>
                                        </DeleteButton>
                                    </ListItem>

                                ))}
                            </div>))
                        }
                    </ConversationList>

                    <Divider/>
                </List>
            </StyledList>
            <StyledReportProblemWrapper open={isOpen}>
                <StyledReportProblemButton>
                    <img src={AlertTriangleSvg} alt="Alert"/>
                    {isOpen && <span>Zgłoś problem</span>}
                </StyledReportProblemButton>
            </StyledReportProblemWrapper>
            <ProfileContainer>
                <img src={AvatarPng} alt="Profile" width="40"/>
                {isOpen &&
                    <span>
                    {user?.name && user?.surname
                        ? `${user.name} ${user.surname}`
                        : user?.email}
                    </span>}
                <ProfileMoreBtn onClick={handleMenuOpen}>
                    <img src={MoreSvg} alt="More"/>
                </ProfileMoreBtn>
                <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleMenuClose}>
                    <MenuItem onClick={handleLogoutClick}>Wyloguj</MenuItem>
                </Menu>
            </ProfileContainer>
        </SidebarContainer>
    );
};

export default Sidebar;
