import React from 'react';
import {Box, CircularProgress} from '@mui/material';
import styled from 'styled-components';

const Spinner = styled(CircularProgress)`
    color: #007db8;
`;

const LoadingSpinner: React.FC = () => {
    return (
        <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
            <Spinner size={130} thickness={3}/>
        </Box>
    );
};

export default LoadingSpinner;
