import React, {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import axios from "axios";
import {
    ActionButtons,
    Header,
    StyledContainer,
    StyledNoDocumentsCell,
    StyledTable,
    StyledTableCard,
    StyledTableCell,
    StyledTableRow
} from "./CollectionDocumentList.styled";
import {Button} from "@mui/material";
import {ReactComponent as PlusIcon} from "../../assets/icons/users-plus.svg";
import {ReactComponent as ChevronLeftIcon} from "../../assets/icons/chevron-left.svg"
import {ReactComponent as ChatIcon} from "../../assets/icons/chat.svg";
import DownloadIcon from '@mui/icons-material/Download';
import PreviewIcon from '@mui/icons-material/Preview';
import AddDocumentDialog from "../../components/collections/AddDocumentDialog";
import {DocumentCollection, DocumentInCollection} from "../../models/models";
import {useApi} from "../../hooks/UseApi";

const CollectionDocumentList: React.FC = () => {
    const {id} = useParams<{ id: string }>();
    const [documentsCollectionId, setDocumentsCollectionId] = useState("");
    const [documents, setDocuments] = useState<DocumentInCollection[]>([]);
    const [collectionName, setCollectionName] = useState("");
    const [loading, setLoading] = useState(true);
    const [addDocumentDialog, setAddDocumentDialog] = useState(false);
    const {downloadDocumentForId} = useApi()
    const navigate = useNavigate();

    const fetchData = async () => {
        try {
            const [collectionRes, docsRes] = await Promise.all([
                axios.get<DocumentCollection>(`/collections/${id}`),
                axios.get(`/documents/by-documents-collection/${id}`, {
                    params: {page: 0, size: 100}
                })
            ]);
            setDocumentsCollectionId(collectionRes.data.id.toString());
            setCollectionName(collectionRes.data.name);
            setDocuments(docsRes.data.content);
        } catch (error) {
            console.error("Error fetching data:", error);
            navigate("/collection/list");
        } finally {
            setLoading(false);
        }
    };

    const handleView = (documentId: number) => {
        navigate(`/documents/view/${documentId}`);
    };

    const handleDownload = async (event: any, documentId: number, fileName: string) => {
        event.stopPropagation();
        event.preventDefault();
        await downloadDocumentForId(documentId, fileName)
    }


    useEffect(() => {
        if (id) {
            fetchData();
        } else {
            navigate("/collection/list");
        }
    }, [id, navigate]);

    if (loading) return <div>Loading...</div>;

    return (
        <StyledContainer>
            <Header>
                <div style={{display: 'flex', alignItems: 'center', gap: '8px', margin: '8px'}}>
                    <Button
                        startIcon={<ChevronLeftIcon/>}
                        onClick={() => navigate(-1)}
                        variant="text"
                        sx={{
                            padding: '8px',
                            '&:hover': {backgroundColor: 'rgba(0,0,0,0.04)'}
                        }}
                    >
                        Wróć do listy kolekcji
                    </Button>

                    <h2>Kolekcja: {collectionName}</h2>
                </div>

                <Button
                    startIcon={<PlusIcon/>}
                    sx={{
                        width: "max-content",
                        marginLeft: "auto",
                        marginBottom: "16px"
                    }}
                    variant="contained"
                    color="primary"
                    onClick={() => setAddDocumentDialog(true)}
                >
                    Dodaj dokument
                </Button>
            </Header>
            <StyledTableCard>
                <StyledTable>
                    <thead>
                    <tr>
                        <StyledTableCell>Nazwa</StyledTableCell>
                        <StyledTableCell>Właściciel</StyledTableCell>
                        <StyledTableCell>Rozmiar</StyledTableCell>
                        <StyledTableCell>Rozszerzenie</StyledTableCell>
                        <StyledTableCell>Data utworzenia</StyledTableCell>
                        <StyledTableCell>Akcje</StyledTableCell>
                    </tr>
                    </thead>
                    <tbody>
                    {documents.length === 0 ? (
                        <StyledTableRow>
                            <StyledNoDocumentsCell colSpan={5}>
                                Brak dokumentów
                            </StyledNoDocumentsCell>
                        </StyledTableRow>
                    ) : (
                        documents.length > 0 && documents.map((document) => (
                            <StyledTableRow key={document.id}>
                                <StyledTableCell>{document.name}</StyledTableCell>
                                <StyledTableCell>{document.owner.email}</StyledTableCell>
                                <StyledTableCell>{(document.size).toFixed(2)} MB</StyledTableCell>
                                <StyledTableCell>{document.extension}</StyledTableCell>
                                <StyledTableCell>
                                    {new Date(document.createdAt).toLocaleDateString()}
                                </StyledTableCell>
                                <StyledTableCell>
                                    <ActionButtons>
                                        <Button
                                            variant="outlined"
                                            color="primary"
                                            startIcon={<PreviewIcon/>}
                                            onClick={() => handleView(document.id)}
                                            sx={{minWidth: '120px'}}
                                        >
                                            Podgląd
                                        </Button>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            startIcon={<DownloadIcon/>}
                                            onClick={(e) => handleDownload(e, document.id, 'document.pdf')}
                                            sx={{minWidth: '140px'}}
                                        >
                                            Pobierz
                                        </Button>
                                        {document.conversationId && (
                                            <Button
                                                variant="outlined"
                                                color="primary"
                                                startIcon={<ChatIcon/>}
                                                onClick={() => navigate(`/chat/${document.conversationId}`)}
                                                sx={{minWidth: '140px'}}
                                            >
                                                Asystent AI
                                            </Button>
                                        )}
                                    </ActionButtons>
                                </StyledTableCell>
                            </StyledTableRow>
                        )))}
                    </tbody>
                </StyledTable>
            </StyledTableCard>
            {addDocumentDialog && (
                <AddDocumentDialog
                    open={addDocumentDialog}
                    onClose={() => setAddDocumentDialog(false)}
                    collectionId={documentsCollectionId}
                    onDocumentCreated={() => fetchData()}
                />)}
        </StyledContainer>
    );
};

export default CollectionDocumentList;
