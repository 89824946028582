import styled from 'styled-components';
import {Dialog, DialogTitle, DialogContent, DialogActions, TextField} from '@mui/material';

export const StyledDialog = styled(Dialog)`
   .MuiPaper-root {
     padding: 1.5rem;
     min-width: 400px;
   }
 `;

export const StyledDialogTitle = styled(DialogTitle)`
   h2 {
     font-size: 1.5rem;
     font-weight: 600;
   }
 `;

export const StyledDialogContent = styled(DialogContent)`
   padding: 1rem 0 !important;
 `;

export const StyledDialogActions = styled(DialogActions)`
   padding: 1rem 0 0 0 !important;
 `;

export const InputField = styled(TextField)`
   margin-top: 1rem !important;
 `;

export const ErrorText = styled.div`
   color: #d32f2f;
   margin-top: 0.5rem;
   font-size: 0.875rem;
 `;