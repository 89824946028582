const collectionTypeTranslation: Record<string, string> = {
    AUTOMATIC: "Automatyczny",
    MANUAL: "Ręczny",
};

export const transformCollections = (collections: any[]): any[] => {
    return collections.map(collection => ({
        ...collection,
        collectionType: collectionTypeTranslation[collection.collectionType],
    }));
};

