import React, {useEffect, useState} from "react";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    List,
    ListItem,
    ListItemText,
    TextField,
    Typography,
} from "@mui/material";
import {
    Header,
    StyledContainer,
    StyledTable,
    StyledTableCard,
    StyledTableCell,
    StyledTableRow,
} from "./GroupDetails.styled";
import axios from "axios";
import {ReactComponent as UsersPlusIcon} from "../../assets/icons/users-plus.svg";
import {useNavigate, useParams} from "react-router-dom";
import {User} from "../../models/models";
import {ReactComponent as UserDeleteIcon} from "../../assets/icons/user-minus.svg";
import {fetchUsers} from "./UserList";

const fetchGroupUsers = async (id: string): Promise<User[]> => {
    try {
        const response = await axios.get(`/groups/${id}/users`);
        return response.data;
    } catch (error) {
        console.error("Error fetching groups:", error);
        alert("Wystąpił błąd podczas pobierania listy grup.");
        return [];
    }
};

const GroupDetails: React.FC = () => {
    const [groupUsers, setGroupUsers] = useState<User[]>([]);
    const [users, setUsers] = useState<User[]>([]);

    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const {id} = useParams();
    const navigate = useNavigate();
    const [searchTerm, setSearchTerm] = useState("");
    const [groupName, setGroupName] = useState<string>("");
    const [error, setError] = useState("");
    const updateQueryParam = () => {
        const params = new URLSearchParams(location.search);
        params.set("name", groupName);
        navigate(`${location.pathname}?${params.toString()}`, {replace: true});
    };
    if (!id) {
        navigate("/admin/group/list")
    }
    const queryParams = new URLSearchParams(location.search);
    const defaultName = queryParams.get("name");

    const filteredUsers = users.filter(user =>
        user.email.toLowerCase().includes(searchTerm.toLowerCase())
    );

    useEffect(() => {
        const name = queryParams.get("name");
        if (name) {
            setGroupName(name)
        }
        const fetchData = async () => {
            const groupUsers = await fetchGroupUsers(id as string);
            const users = await fetchUsers();

            const groupUserEmails = new Set(groupUsers?.map(user => user.email));
            const filteredUsers = users?.filter(user => !groupUserEmails.has(user.email));

            setGroupUsers(groupUsers);
            setUsers(filteredUsers || []);
        };

        fetchData();
    }, []);


    const removeUserFromGroup = async (userId: number) => {
        try {
            await axios.delete(`/groups/${id}/users/${userId}`);
            setIsDialogOpen(false);
            const group = await fetchGroupUsers(id as string);
            setGroupUsers(group);
        } catch (error) {
            console.error("Error creating group:", error);
            alert("Wystąpił błąd podczas tworzenia grupy.");
        }
    };

    const addUserToGroup = async (userId: number) => {
        try {
            await axios.post(`/groups/${id}/users`, {userId: [userId]});
            setIsDialogOpen(false);
            const group = await fetchGroupUsers(id as string);
            setGroupUsers(group);
        } catch (error) {
            console.error("Error creating group:", error);
            alert("Wystąpił błąd podczas tworzenia grupy.");
        }
    };
    const updateGroupName = async () => {
        try {
            await axios.patch(`/groups`, {id, name: groupName});
            setGroupName(groupName);
            updateQueryParam();
            setError("");
        } catch (error: any) {
            if (error?.response && error?.response.data) {
                setError("Nazwa grupy już istnieje.");
            } else {
                alert("Wystąpił błąd podczas zmiany nazwy grupy.");
            }
        }
    };
    return (
        <StyledContainer>
            <Header>
                <div style={{display: "flex", alignItems: "center", gap: "16px"}}>
                    <h2>Szczegóły Grupy</h2>
                    <TextField
                        variant="standard"
                        value={groupName}
                        onChange={(e) => setGroupName(e.target.value)}
                        inputProps={{
                            style: {
                                minWidth: "120px",
                                textAlign: "center",
                                width: `${groupName.length * 10 + 10}px`,
                                fontSize: "1.2rem",
                                fontWeight: "bold",
                            },
                        }}
                    />
                    {groupName && defaultName !== groupName && (
                        <Button variant="outlined" onClick={updateGroupName}>
                            Zatwierdź
                        </Button>
                    )}
                </div>
                {error && <Typography color="error">{error}</Typography>}
            </Header>
            <Button
                startIcon={<UsersPlusIcon/>}
                sx={{
                    width: "max-content",
                    marginLeft: "auto",
                    marginBottom: "16px"
                }}
                variant="contained" color="primary" onClick={() => setIsDialogOpen(true)}>
                Dodaj użytkownika
            </Button>
            {groupUsers.length > 0 &&
                <StyledTableCard>
                    <StyledTable>
                        <thead>
                        <tr>
                            <StyledTableCell>Email</StyledTableCell>
                            <StyledTableCell>Akcje</StyledTableCell>
                        </tr>
                        </thead>
                        <tbody>
                        {groupUsers.map((user) => (
                            <StyledTableRow key={user.userId}>
                                <StyledTableCell>{user.email}</StyledTableCell>

                                <StyledTableCell>
                                    <Button variant={'outlined'} sx={{marginRight: '8px'}}
                                            onClick={() => removeUserFromGroup(user.userId as number)}
                                    ><UserDeleteIcon/>
                                    </Button>
                                </StyledTableCell>
                            </StyledTableRow>
                        ))}
                        </tbody>
                    </StyledTable>
                </StyledTableCard>
            }
            {groupUsers.length === 0 && <h2 style={{textAlign: 'center', marginTop: '24px'}}>Brak użytkowników</h2>}

            <Dialog open={isDialogOpen} onClose={() => setIsDialogOpen(false)}>
                <DialogTitle>Dodaj użytkownika do grupy</DialogTitle>

                <DialogContent
                    sx={{padding: "32px 48px !important", width: "450px", display: "flex", flexDirection: "column"}}>
                    <TextField
                        fullWidth
                        margin="dense"
                        label="Szukaj"
                        variant="outlined"
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        sx={{marginBottom: "16px"}}
                    />

                    <List
                        sx={{
                            maxHeight: "320px",
                            overflowY: "auto",
                            border: "1px solid #ddd",
                            borderRadius: "8px",
                            padding: "8px",
                            background: "#fafafa"
                        }}
                    >
                        {filteredUsers.map(user => (
                            <ListItem
                                key={user.email}
                                button
                                onClick={() => addUserToGroup(user.id as number)}
                                sx={{
                                    borderRadius: "4px",
                                    "&:hover": {backgroundColor: "#f0f0f0"}
                                }}
                            >
                                <ListItemText primary={user.email}/>
                            </ListItem>
                        ))}
                    </List>
                </DialogContent>

                <DialogActions sx={{padding: "20px"}}>
                    <Button onClick={() => setIsDialogOpen(false)}>Anuluj</Button>
                </DialogActions>
            </Dialog>
        </StyledContainer>
    );
};

export default GroupDetails;
