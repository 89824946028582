import styled from 'styled-components';
import {FormControl, Switch} from '@mui/material';

export const ChatContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 8px 20px;
    max-height: 750px;
`;

export const StyledFormControl = styled(FormControl)`
    && {
        .MuiSelect-select {
            padding: 12px;
        }

        .MuiOutlinedInput-root {
            border-radius: 8px;
            border-color: #a7b2bc;
        }

        .MuiOutlinedInput-notchedOutline {
            border-color: #a7b2bc;
        }

    }
`;

export const PdfWrapper = styled.div`
    display: none;
`;

export const ChatWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;
`;

export const ChatCard = styled.div`
    display: flex;
    flex-direction: column;
    max-height: 750px;
    padding: 24px 60px 40px 60px;
    border-radius: 16px;
    background-color: #fff;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    border: 1px solid #e5edf3;
    width: 740px;
`;

export const HeaderActions = styled.div`
    text-align: right;
`;

export const LogoWrapper = styled.div`
    text-align: center;
    margin: 20px 0;

    img {
        width: 95px;
        height: auto;
    }
`;

export const ChatHeader = styled.div<{ $isBigSpace: boolean }>`
    font-weight: 700;
    font-size: 25px;
    margin-bottom: ${(props) => (props.$isBigSpace ? '40px' : '20px')};
    margin-top: ${(props) => (props.$isBigSpace ? '16px' : '0')};
    text-align: center;
`;

export const SelectTopics = styled.div`
    margin-bottom: 20px;
    width: 100%;
`;

export const UseDocumentsWrapper = styled.div`
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 17px;
    margin-top: 12px;
    margin-bottom: 24px;
`

export const StyledSwitch = styled(Switch)({
    '& .MuiSwitch-switchBase.Mui-checked': {
        color: '#007DB8',
    },
    '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
        backgroundColor: '#007DB8',
    },
    '& .MuiSwitch-track': {
        backgroundColor: '#A7B2BC',
    },
});
