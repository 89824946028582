import styled from 'styled-components';
import {IconButton, TextField} from '@mui/material';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
`

export const StyledForm = styled.form`
    position: relative;
    display: flex;
    align-items: center;
    border: 1px solid #cacfd2;
    border-radius: 8px;
    background: #ffffff;
    //padding-left: 4px;
    padding: 4px;
`;

export const MicrophoneButton = styled(IconButton)<{ $isRecording: boolean }>`
    color: ${(props) => (props.$isRecording ? 'red' : '#a7b2bc') + ' !important'};
`

export const InputActions = styled.div<{ $attachedFile: boolean }>`
    display: flex;
    align-items: end;
    margin-right: 8px;
    height: 100%;

    button {
        width: 36px;
        height: 36px;
        padding: 0;
        color: #a7b2bc;
    }
`;


export const SendButtonWrapper = styled.div`
    display: flex;
    align-items: center;
    margin-left: 8px;

    button {
        width: 44px;
        height: 41px;
        padding: 0;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;

        &:hover {
            background: rgba(224, 221, 222, 0.7);
        }

        &:disabled {
            opacity: 0.45;
        }
    }
`;
export const FileInfoWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #f5f5f5;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 8px 12px;
    width: max-content;
    margin-top: 10px;
    gap: 8px;
`;

export const RemoveButton = styled.button`
    background: none;
    border: none;
    color: #595959;
    cursor: pointer;
    font-size: 14px;
    bottom: 1px;
    font-weight: bold;
    position: relative;

    &::after {
        content: '✕';
    }
`;
export const FileInfoInsideInput = styled.div`
    position: absolute;
    top: 4px;
    left: -81px;
    font-size: 14px;
    margin-bottom: -2px;
    color: #595959;
    background: rgba(0, 0, 0, 0.08);
    padding: 4px 8px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    gap: 8px;
    max-width: 50%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
`;

export const StyledTextField = styled(TextField)<{ $hasFile: boolean }>`
    && {
        flex: 1;
        border: none;
        min-height: ${(props) => (props.$hasFile ? '70px' : 'auto')};

        .MuiOutlinedInput-root {
            border: none;
            font-size: 16px;
            padding: ${(props) => (props.$hasFile ? '41px 8px 8px' : '8px')};
            position: relative;
        }

        .MuiOutlinedInput-notchedOutline {
            display: none;
        }

        .MuiInputBase-input {
            padding: 0;
        }
    }
`;
