import styled from 'styled-components';
import {Button, TextField} from '@mui/material';

export const StyledTextField = styled(TextField)`
    && {
        .MuiOutlinedInput-root {
            border-radius: 12px;
            background-color: transparent;

            &.Mui-focused .MuiOutlinedInput-notchedOutline {
                border-color: #007db8;
            }
        }

        .MuiOutlinedInput-notchedOutline {
            border-color: #a7b2bc;
        }

        .MuiInputLabel-root {
            color: #5a5a5a;

            &.Mui-focused {
                color: #007db8;
            }
        }

        input:-webkit-autofill {
            box-shadow: 0 0 0px 1000px white inset;
            -webkit-text-fill-color: #000000;
            font-size: 1rem;
        }
    }
`;

export const LoginContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f5f8fa;
`;

export const LoginCard = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 40px;
    border-radius: 16px;
    background-color: #fff;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    border: 1px solid #e5edf3;
    width: 500px;
`;

export const LogoWrapper = styled.div`
    text-align: center;
    margin-bottom: 20px;

    img {
        width: 80px;
        height: auto;
    }
`;

export const FormWrapper = styled.div`
    width: 100%;
`;

export const StyledButton = styled(Button)`
    && {
        width: 100%;
        margin-top: 20px;
        text-transform: none;
        font-weight: 600;
        border-radius: 8px;

        &:first-of-type {
            margin-top: 30px;
            background-color: #007db8;
            color: #fff;

            &:hover {
                background-color: #005f8d;
            }
        }

        &:last-of-type {
            border-color: #a7b2bc;
            color: #007db8;

            &:hover {
                background-color: rgba(0, 125, 184, 0.1);
            }
        }
    }
`;
