import React, {useEffect, useState} from 'react';
import {
    AddButton,
    CancelButton,
    Card,
    CloseButton,
    CloseButtonWrapper,
    Container,
    DocumentsContainer,
    DownloadButton,
    Header,
    Input,
    LogoImage,
    LogoWrapper,
    ModalBody,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    NoCategorySelected,
    SelectWrapper,
    SubmitButton,
    Table,
    TableCell,
    TableHeader,
    TableRow,
} from './ManageDocuments.styled';
import CloseIcon from '@mui/icons-material/Close';
import DownloadIcon from '@mui/icons-material/Download';
import AddIcon from '@mui/icons-material/Add';
import Logo from 'assets/images/asystent_it_logo.png';
import {useNavigate} from "react-router-dom";
import {useApi} from "../hooks/UseApi";
import {Category, PdfDocument} from "../models/models";

const ManageDocuments = () => {
    const [categories, setCategories] = useState<Category[]>([]);
    const [documents, setDocuments] = useState<PdfDocument[]>([]);
    const [selectedCategory, setSelectedCategory] = useState('');
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [newDocumentName, setNewDocumentName] = useState('');
    const [newDocumentFile, setNewDocumentFile] = useState<File | null>(null);
    const navigate = useNavigate();
    const { fetchCategories, fetchDocumentsByCategoryId, downloadDocumentForId, uploadDocument } = useApi();

    const handleBackToChat = () => {
        const url = '/chat'
        navigate(url);
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetchCategories();
                setCategories(response);
            } catch (error) {
                console.error('Failed to fetch categories:', error);
            }
        };

        void fetchData();
    }, []);

    const handleCategoryChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const categoryId = event.target.value;
        setSelectedCategory(categoryId);
        if (!categoryId) {
            setDocuments([]);
            return;
        }
        const fetchData = async () => {
            try {
                const response = await fetchDocumentsByCategoryId(categoryId);
                setDocuments(response);
            } catch (error) {
                console.error('Failed to fetch documents:', error);
            }
        };

        void fetchData();
    };

    const downloadDocument = async (event: any, documentId: number, fileName: string) => {
        event.stopPropagation();
        event.preventDefault();
        await downloadDocumentForId(documentId, fileName)
    };

    const openModal = () => {
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
        setNewDocumentName('');
        setNewDocumentFile(null);
    };

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files && event.target.files.length > 0) {
            setNewDocumentFile(event.target.files[0]);
        }
    };

    const handleSubmit = async (event: React.FormEvent) => {
        event.preventDefault();
        if (!newDocumentFile || !newDocumentName || !selectedCategory) {
            alert('Proszę wypełnić wszystkie pola.');
            return;
        }
        try {
            await uploadDocument(newDocumentFile, newDocumentName, selectedCategory);
            alert('Dokument został dodany pomyślnie.');
            closeModal();
            // Odśwież listę dokumentów
            const response = await fetchDocumentsByCategoryId(selectedCategory);
            setDocuments(response);
        } catch (error) {
            console.error('Failed to upload document:', error);
            alert('Wystąpił błąd podczas dodawania dokumentu.');
        }
    };

    return (
        <Container>
            <Card>
                <CloseButtonWrapper>
                    <CloseButton onClick={() => handleBackToChat()}>
                        <CloseIcon/>
                    </CloseButton>
                </CloseButtonWrapper>

                <LogoWrapper>
                    <LogoImage src={Logo} alt="Asystent IT Logo"/>
                </LogoWrapper>

                <Header>Zarządzanie dokumentami</Header>

                <SelectWrapper>
                    <select onChange={handleCategoryChange} value={selectedCategory}>
                        <option value="">Wybierz kategorię</option>
                        {categories.map((category: Category) => (
                            <option key={category.id} value={category.id}>
                                {category.name}
                            </option>
                        ))}
                    </select>
                </SelectWrapper>

                <DocumentsContainer>
                    <h2>Dokumenty</h2>
                    {selectedCategory ? (
                        <>
                            <AddButton onClick={openModal}>
                                <AddIcon /> Dodaj Dokument
                            </AddButton>
                            {documents.length > 0 ? (
                                <Table>
                                    <thead>
                                    <TableHeader>
                                        <th>Tytuł</th>
                                        <th>Źródło</th>
                                        <th>Akcje</th>
                                    </TableHeader>
                                    </thead>
                                    <tbody>
                                    {documents.map((document: any) => (
                                        <TableRow key={document.id}>
                                            <TableCell>{document.name}</TableCell>
                                            <TableCell>{document.type || '-'}</TableCell>
                                            <TableCell>
                                                <DownloadButton
                                                    onClick={(e) => downloadDocument(e, document.id, document.fileName || 'unknown.pdf')}>
                                                    <DownloadIcon sx={{color: 'gray.500'}}/>
                                                </DownloadButton>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                    </tbody>
                                </Table>
                            ) : (
                                <p>Brak dokumentów w tej kategorii.</p>
                            )}
                        </>
                    ) : (
                        <NoCategorySelected>
                            Wybierz kategorię, aby zobaczyć dokumenty.
                        </NoCategorySelected>
                    )}
                </DocumentsContainer>
            </Card>

            {isModalOpen && (
                <ModalOverlay>
                    <ModalContent>
                        <ModalHeader>Dodaj Nowy Dokument</ModalHeader>
                        <form onSubmit={handleSubmit}>
                            <ModalBody>
                                <Input
                                    type="text"
                                    placeholder="Nazwa dokumentu"
                                    value={newDocumentName}
                                    onChange={(e) => setNewDocumentName(e.target.value)}
                                    required
                                />
                                <Input
                                    type="file"
                                    accept=".pdf,.doc,.docx"
                                    onChange={handleFileChange}
                                    required
                                />
                            </ModalBody>
                            <ModalFooter>
                                <SubmitButton type="submit">Dodaj</SubmitButton>
                                <CancelButton type="button" onClick={closeModal}>
                                    Anuluj
                                </CancelButton>
                            </ModalFooter>
                        </form>
                    </ModalContent>
                </ModalOverlay>
            )}
        </Container>
    );
};

export default ManageDocuments;
