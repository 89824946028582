import styled from 'styled-components';
import {Button, Dialog, Input, List, ListItem} from "@mui/material";

export const StyledDialog = styled(Dialog)`
    .MuiPaper-root {
        padding: 20px;
        background: white;
        border-radius: 8px;
        box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
        width: 700px;
    }
`;

export const SearchInput = styled(Input)`
    width: 100%;
    padding: 8px;
    margin-bottom: 15px;
`;

export const GroupList = styled(List)`
    max-height: 300px;
    overflow-y: auto;
    margin: 10px 0;
    border: 1px solid #e6e6e6;
    border-radius: 20px;
`;

export const GroupItem = styled(ListItem)`
    transition: background 200ms ease;
    cursor: pointer;
    font-weight: 500;
    border-radius: 20px;

    &:hover {
        background: rgba(128, 128, 128, 0.16);
    }

    &.MuiListItem-root {
        justify-content: center;
    }
`;

export const StyledButton = styled(Button)`
    &.MuiButton-root {
        margin: 5px;
    }
`;
