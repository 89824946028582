import React, {useState} from 'react';
import Modal from '@mui/material/Modal';
import {IconButton} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const ImagePreview = ({ src }: { src: string }) => {
    const [open, setOpen] = useState(false);

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    return (
        <>
            <button
                onClick={handleOpen}
                style={{
                    background: 'none',
                    border: 'none',
                    padding: 0,
                    cursor: 'pointer',
                    display: 'flex',
                    justifyContent: 'center'
                }}
                aria-label="Open image preview"
            >
                <img src={src} alt="Preview" style={{ width: 160, height: '100%', borderRadius: '8px' }} />
            </button>
            <Modal open={open} onClose={handleClose}>
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '100vh',
                        position: 'relative',
                        backgroundColor: 'rgba(0, 0, 0, 0.8)',
                    }}
                >
                    <IconButton
                        onClick={handleClose}
                        style={{
                            position: 'absolute',
                            top: '20px',
                            right: '20px',
                            backgroundColor: 'rgba(255, 255, 255, 0.7)',
                        }}
                        aria-label="Close image preview"
                    >
                        <CloseIcon />
                    </IconButton>
                    <img src={src} alt="Full Preview" style={{ maxWidth: '90%', maxHeight: '90%', borderRadius: '8px' }} />
                </div>
            </Modal>
        </>
    );
};

export default ImagePreview;
