import React, {useEffect, useState} from 'react';
import axios from 'axios';
import {BackBtn, Header, StyledContainer} from './UserCollectionList.styled';
import {useNavigate} from "react-router-dom";
import {DocumentCollection} from "../../models/models";
import CollectionTable from "../../components/collections/CollectionTable";
import {ReactComponent as ChevronLeftIcon} from "../../assets/icons/chevron-left.svg"
import {transformCollections} from "../../utils/format-collections";

const UserCollectionList: React.FC = () => {
    const [collections, setCollections] = useState<DocumentCollection[]>([]);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();

    const fetchCollections = async () => {
        try {
            const response = await axios.get('/documents/by-collection');
            setCollections(transformCollections(response.data.content));
        } catch (error) {
            console.error('Error fetching collections:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchCollections();
    }, []);

    const handleDelete = async (collectionId: number) => {
        try {
            await axios.delete(`/collections/${collectionId}/documents`);
            setCollections(collections.filter(c => c.id !== collectionId));
        } catch (error) {
            console.error('Error deleting collection:', error);
        }
    };


    const handleCollectionPreview = (collectionId: number) => {
        navigate(`/collections/${collectionId}/documents`)
    }

    if (loading) return <div>Loading...</div>;

    return (
        <>
            <StyledContainer>
                <BackBtn
                    startIcon={<ChevronLeftIcon/>}
                    onClick={() => navigate(-1)}
                    variant="text"
                    sx={{
                        padding: '8px',
                        '&:hover': {backgroundColor: 'rgba(0,0,0,0.04)'}
                    }}
                >
                    Wróć
                </BackBtn>
                <Header>
                    <h2>Kolekcje Dokumentów</h2>

                </Header>
                <CollectionTable
                    collections={collections}
                    isAdmin={true}
                    onPreview={handleCollectionPreview}
                    onDelete={handleDelete}
                />

            </StyledContainer>
        </>
    );
};

export default UserCollectionList;

