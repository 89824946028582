import React from 'react';
import styled from 'styled-components';

const StyledButton = styled.button`
    background-color: #007DB8;
    color: white;
    padding: 8px 12px;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    width: fit-content;
    margin: auto;
    font-weight: 600;
    &:hover {
        background-color: #005F8A;
    }

    &:disabled {
        background-color: #A7B2BC;
        cursor: not-allowed;
    }
`;

const DownloadButton = ({documentId, fileName, onDownload}: any) => {
    const handleDownload = (event: any) => {
        event?.stopPropagation();
        event?.preventDefault();
        onDownload(documentId, fileName)
    }
    return (
        <StyledButton onClick={(event) => handleDownload(event)}>
            {fileName}
        </StyledButton>
    )
};

export default DownloadButton;
