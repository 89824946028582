export interface Category {
    id: number;
    name: string;
    disabled: boolean;
}

export interface PdfDocument {
    id: number;
    name: string;
    categoryName: string
}

export interface UploadDocumentResponse {
    documentId: number
}

export enum UserRole {
    SYSTEM_ADMIN = "SYSTEM_ADMIN",
    USER = "USER"
}

export interface User {
    userId?: number;
    id?: number;
    email: string;
    createdAt: string;
    role: UserRole;
    activated: boolean;
}

export interface CollectionPermission {
    groupId: number;
    permissionType: "READ_ONLY" | "EDIT";
}

export interface DocumentCollectionPermissionQueryResult {
    groupId: number;
    name: string;
    permission: "READ_ONLY" | "EDIT";
}

export interface GroupQueryResult {
    id: number;
    name: string;
}

export interface DocumentCollection {
    id: number;
    name: string;
    lastModificationDate: string;
    amountOfDocuments: number;
    owner: {
        email: string;
    };
    collectionType: string
}

export interface DocumentInCollection {
    id: number,
    name: string;
    createdAt: string;
    extension: string,
    size: number;
    owner: {
        email: string;
    };
    conversationId?: number;
}

export interface ConversationResponse {
    id: number;
    title: string;
    createdAt: string;
}

export interface MessageResponse {
    author: "USER" | "CHATBOT";
    content: string;
    timestamp: string;
    documentId?: number;
    documentName?: string;
}

export interface ConversationHistoryResponse {
    messages: MessageResponse[];
}
