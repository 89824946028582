import React, {useEffect, useState} from 'react';
import {Button,} from '@mui/material';
import {ChatCard, ChatContainer, ChatHeader, ChatWrapper, HeaderActions, LogoWrapper, PdfWrapper,} from './Chat.styled';
import AsystentLogo from 'assets/images/asystent_it_logo.png';
import {useNavigate, useParams} from "react-router-dom";
import {formatMessageContent, responseHandler} from "../../utils/response-handler";
import {ReactComponent as CollectionIcon} from "assets/icons/catalog.svg";
import axios from "axios";
import {ConversationHistoryResponse} from "../../models/models";
import {useUser} from "../../core/UserContext";
import {useApi} from "../../hooks/UseApi";
import ChatMessages from "../../components/chat/ChatMessages";
import ChatInput from "../../components/chat/ChatInput";

export interface Message {
    author: string,
    text: any,
    isSystem: boolean,
    isLoading?: boolean,
    isError?: boolean,
    documentId?: number,
    documentName?: string
}


const Chat = () => {
    const {id} = useParams<{ id?: string }>()
    const [messages, setMessages] = useState<Message[]>([]);
    const navigate = useNavigate();
    const {loading, error, sendMessage, downloadDocumentForId} = useApi();
    const [attachedFile, setAttachedFile] = useState<File | null>(null);
    const {user, triggerConversationRefresh} = useUser();

    useEffect(() => {
        if (!id) {
            setMessages([])
            return
        }
        const fetchMessages = async () => {
            if (id) {
                try {
                    const response = await axios.get<ConversationHistoryResponse>(`/chat/conversation/${id}`);
                    setMessages(response.data.messages.map(msg => ({
                        author: msg.author === 'CHATBOT' ? 'Asystent IT' : msg.author,
                        text: msg.author === 'CHATBOT' ? formatMessageContent(msg.content, downloadDocumentForId) : msg.content,
                        isSystem: msg.author === 'CHATBOT',
                        isLoading: false,
                        documentId: msg.documentId,
                        documentName: msg.documentName
                    })));
                } catch (error) {
                    console.error('Error fetching messages:', error);
                }
            }
        };

        void fetchMessages();
    }, [id]);

    const handleNavigation = () => {
        navigate('/collections/list');
    };

    const createConversation = async () => {
        try {
            const response = await axios.post('/chat/conversation');
            return response.data.id;
        } catch (error) {
            console.error('Error creating conversation:', error);
        }
    }

    const handleSendMessage = async (text: string) => {
        const isUserName = user?.name || user?.surname;
        const author = isUserName ? `${user?.name} ${user?.surname}` : '';
        console.log(" attachedFile?.name: ", attachedFile?.name);

        const userMessage: Message = {
            author,
            text,
            isSystem: false,
            documentId: -1,
            documentName: attachedFile?.name
        };
        setMessages((prev) => [...prev, userMessage]);

        const loadingMessage: Message = {author: 'Asystent IT', text: '', isSystem: true, isLoading: true};
        setMessages((prev) => [...prev, loadingMessage]);
        try {
            if (id) {
                await sendMessage(text, (text: string) => responseHandler(text, setMessages, downloadDocumentForId), attachedFile, id, () => triggerConversationRefresh());
            } else {
                const conversationId = await createConversation()
                await sendMessage(text, (text: string) => responseHandler(text, setMessages, downloadDocumentForId), attachedFile, conversationId, () => triggerConversationRefresh());
                navigate(`/chat/${conversationId}`, {replace: true});
            }

        } catch (e) {
            console.log("Złapany")
        }
    };

    const handleFileChange = (file: File | null) => {
        console.log("handleFileChange: ", file);
        setAttachedFile(file)
    }

    return (
        <ChatContainer>
            <PdfWrapper>
                <div>
                    <canvas id="pdf-canvas"></canvas>
                    <div>
                        <button>Poprzednia</button>
                        <span>
              Strona: <span id="page-num"></span> / <span id="page-count"></span>
            </span>
                        <button>Następna</button>
                    </div>
                </div>
            </PdfWrapper>
            <ChatWrapper>
                <ChatCard>
                    <HeaderActions>
                        <Button
                            variant="text"
                            onClick={handleNavigation}
                            startIcon={<CollectionIcon/>}
                        >
                            Moje Kolekcje
                        </Button>
                    </HeaderActions>
                    <LogoWrapper>
                        <img src={AsystentLogo} alt="Asystent IT Logo"/>
                    </LogoWrapper>
                    <ChatHeader $isBigSpace={messages.length === 0}>Jak mogę Ci dzisiaj pomóc?</ChatHeader>
                    <ChatMessages messages={messages}/>
                    <ChatInput onSendMessage={handleSendMessage} onFileChange={handleFileChange}/>
                </ChatCard>
            </ChatWrapper>
        </ChatContainer>
    );
};

export default Chat;
