import React from 'react';
import {CssBaseline, ThemeProvider} from '@mui/material';
import theme from "../theme/theme";
import {Children} from "../models/base.models";
import {Provider} from "../contexts/Context";


const AppProviders = ({children}: Children): React.ReactElement => (
    <Provider>
        <ThemeProvider theme={theme}>
            <CssBaseline/>
            {children}
        </ThemeProvider>
    </Provider>
)
export default AppProviders;
