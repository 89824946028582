import React, {useState} from 'react';
import {Outlet} from 'react-router-dom';
import styled from 'styled-components';
import Sidebar from './Sidebar';
import NavBar from "./Navbar";

const LayoutWrapper = styled.div`
    display: flex;
    height: 100vh;
    flex-direction: column;
`;

const ContentWrapper = styled.div`
    display: flex;
    flex: 1;
`;

const MainContent = styled.div<{ $sidebarOpen: boolean }>`
    flex: 1;
    margin-left: ${({$sidebarOpen}) => ($sidebarOpen ? '280px' : '60px')};
    transition: margin-left 0.3s;
    padding: 20px;
    overflow-y: auto;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const Layout = () => {
    const [isSidebarOpen, setSidebarOpen] = useState(true);

    const handleSidebarToggle = () => {
        setSidebarOpen(!isSidebarOpen);
    };

    return (
        <LayoutWrapper>
            <NavBar/>
            <ContentWrapper>
                <Sidebar isOpen={isSidebarOpen} onToggle={handleSidebarToggle}/>
                <MainContent $sidebarOpen={isSidebarOpen}>
                    <Outlet/>
                </MainContent>
            </ContentWrapper>
        </LayoutWrapper>
    );
};

export default Layout;
