import styled from 'styled-components';
import {Dialog} from "@mui/material";

export const StyledDialog = styled(Dialog)`
    .MuiPaper-root {
        padding: 20px;
        background: white;
        border-radius: 8px;
        box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
        width: 700px;
    }
`;

export const ModalOverlay = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
`;

export const ModalContent = styled.div`
    background: white;
    padding: 20px;
    border-radius: 8px;
    width: 400px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
`;

export const ModalHeader = styled.h3`
    margin-bottom: 20px;
    text-align: center;
`;

export const ModalBody = styled.div`
    display: flex;
    flex-direction: column;
    gap: 15px;
`;

export const ModalFooter = styled.div`
    display: flex;
    justify-content: flex-end;
    gap: 10px;
    margin-top: 20px;
`;

export const Input = styled.input`
    padding: 10px;
    border: 1px solid #dde2e6;
    border-radius: 8px;
    font-size: 16px;
    width: 100%;
`;

export const SubmitButton = styled.button`
    background-color: #4CAF50;
    color: white;
    padding: 10px 16px;
    border: none;
    border-radius: 8px;
    cursor: pointer;

    &:hover {
        background-color: #45a049;
    }
`;

export const CancelButton = styled.button`
    background-color: #f44336;
    color: white;
    padding: 10px 16px;
    border: none;
    border-radius: 8px;
    cursor: pointer;

    &:hover {
        background-color: #d32f2f;
    }
`;
