import React, {createContext, ReactNode, useContext, useEffect, useState} from 'react';
import axios from 'axios';
import {useAuth} from "./AuthContext";
import {accessTokenKey} from "./AuthInterceptor";

interface User {
    id: number;
    name: string;
    surname: string;
    email: string;
    role: string;
}

interface UserContextType {
    user: User | null;
    fetchUserData: () => Promise<void>;
    refreshConversations: number;
    triggerConversationRefresh: () => void;
}

const UserContext = createContext<UserContextType | undefined>(undefined);

export const UserProvider: React.FC<{ children: ReactNode }> = ({children}) => {
    const [user, setUser] = useState<User | null>(null);
    const {isAuthenticated} = useAuth();
    const [refreshConversations, setRefreshConversations] = useState(0)

    const fetchUserData = async () => {
        const token = localStorage.getItem(accessTokenKey);
        if (isAuthenticated && token) {
            try {
                const response = await axios.get<User>(`/users/currentUserDetails`);
                setUser(response.data);
            } catch (error) {
                console.error('Błąd pobierania danych użytkownika:', error);
            }
        }
    };

    const triggerConversationRefresh = () => {
        setRefreshConversations(prev => prev + 1);
    }

    useEffect(() => {
        if (!user) {
            fetchUserData();
        }
    }, [isAuthenticated, user]);

    return (
        <UserContext.Provider value={{user, fetchUserData, refreshConversations, triggerConversationRefresh}}>
            {children}
        </UserContext.Provider>
    );
};

export const useUser = () => {
    const context = useContext(UserContext);
    if (!context) {
        throw new Error('useUser must be used within a UserProvider');
    }
    return context;
};
