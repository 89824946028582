import React, {useEffect, useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import styled from "styled-components";
import {ReactComponent as UsersIcon} from "../../assets/icons/users.svg";
import {ReactComponent as UserIcon} from "../../assets/icons/user.svg";
import {ReactComponent as CollectionIcon} from "../../assets/icons/catalog.svg";
import {ReactComponent as SettingsIcon} from "../../assets/icons/clock-refresh.svg";

const menuItems = [
    {path: "/admin/user/list", label: "Użytkownicy", icon: <UserIcon/>},
    {path: "/admin/group/list", label: "Grupy", icon: <UsersIcon/>},
    {path: "/admin/collections/list", label: "Kolekcje Dokumentów", icon: <CollectionIcon/>},
    {path: "/admin/scheduler", label: "Aktualizacja Kolekcji", icon: <SettingsIcon/>},
];

const AdminMenuWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`;
const AdminButton = styled.button<{ $active: boolean; $isOpen: boolean }>`
    display: flex;
    gap: 14px;
    align-items: center;
    justify-content: ${({$isOpen}) => ($isOpen ? "flex-start" : "center")};
    background: ${({$active}) => ($active ? "#f4f4f4" : "transparent")};
    border: 1px solid transparent;
    border-radius: 8px;
    padding: 10px ${({$isOpen}) => ($isOpen ? "14px" : "10px")};
    cursor: ${({$active}) => ($active ? "default" : "pointer")};
    font-size: ${({$isOpen}) => ($isOpen ? "17px" : "20px")};
    font-weight: 500;
    transition: background 0.3s, padding 0.3s, font-size 0.3s;
    pointer-events: ${({$active}) => ($active ? "none" : "auto")};
    width: ${({$isOpen}) => ($isOpen ? "100%" : "50px")};
    height: 50px;

    &:hover {
        background: #efefef;
    }
`;

const Arrow = styled.div<{ $isExpanded: boolean; $isOpen: boolean }>`
    width: 10px;
    height: 10px;
    border-left: 2px solid #1976d2;
    border-bottom: 2px solid #1976d2;
    transform: ${({$isExpanded}) => ($isExpanded ? "rotate(-45deg)" : "rotate(135deg)")};
    transition: transform 0.3s ease-in-out;
    ${({$isOpen}) => !$isOpen && "display: none;"}
`;

const DropdownMenu = styled.div<{ $open: boolean; $isOpen: boolean }>`
    display: flex;
    flex-direction: column;
    background: #fff;
    border: 1px solid transparent;
    gap: 4px;
    border-radius: 8px;
    margin-top: 8px;
    overflow: hidden;
    max-height: ${({$open}) => ($open ? "300px" : "0")};
    opacity: ${({$open}) => ($open ? "1" : "0")};
    transition: max-height 0.3s ease-in-out, opacity 0.3s ease-in-out;
    ${({$isOpen}) => !$isOpen && "align-items: center; width: 50px;"}
`;

const DropdownItem = styled.div<{ $active: boolean; $isOpen: boolean }>`
    display: flex;
    align-items: center;
    gap: ${({$isOpen}) => ($isOpen ? "12px" : "0")};
    padding: ${({$isOpen}) => ($isOpen ? "10px 16px 10px 30px" : "10px")};
    font-size: 15px;
    border-radius: 6px;
    cursor: pointer;
    pointer-events: ${({$active}) => ($active ? "none" : "auto")};
    background: ${({$active}) => ($active ? "#efefef" : "transparent")};
    transition: background 0.3s, padding 0.3s, gap 0.3s;
    justify-content: ${({$isOpen}) => ($isOpen ? "flex-start" : "center")};
    width: ${({$isOpen}) => ($isOpen ? "100%" : "50px")};

    &:hover {
        background: #efefef;
    }

    svg {
        width: 20px;
        height: 20px;
        color: #1976d2;
    }

    ${({$isOpen}) => !$isOpen && "span { display: none; }"}
`;

const AdminDropdown: React.FC<{ isOpen: boolean }> = ({isOpen}) => {
    const navigate = useNavigate();
    const location = useLocation();
    const isAnyActive = menuItems.some((item) => location.pathname.includes(item.path));
    const [isExpanded, setIsExpanded] = useState(isAnyActive);

    useEffect(() => {
        if (isAnyActive) setIsExpanded(true);
    }, [isAnyActive]);

    return (
        <AdminMenuWrapper>
            <AdminButton
                onClick={() => !isAnyActive && setIsExpanded((prev) => !prev)}
                $active={isAnyActive}
                $isOpen={isOpen}
            >
                <Arrow $isExpanded={isExpanded} $isOpen={isOpen}/>
                <span>{isOpen ? "Panel Administratora" : "A"}</span>
            </AdminButton>
            <DropdownMenu $open={isExpanded} $isOpen={isOpen}>
                {menuItems.map(({path, label, icon}) => (
                    <DropdownItem
                        key={path}
                        onClick={() => navigate(path)}
                        $active={location.pathname.includes(path)}
                        $isOpen={isOpen}
                    >
                        {icon}
                        {isOpen && label}
                    </DropdownItem>
                ))}
            </DropdownMenu>
        </AdminMenuWrapper>
    );
};

export default AdminDropdown;
