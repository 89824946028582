import React from 'react';
import {ActionButton, StyledTable, StyledTableCard, StyledTableCell, StyledTableRow} from './CollectionTable.styled';
import {DocumentCollection} from "../../models/models";
import {EditDocumentCollectionPermission} from "../../pages/admin/AdminCollectionList";
import {ReactComponent as TrashIcon} from "../../assets/icons/trash.svg";
import {ReactComponent as ArrowCircleRight} from "../../assets/icons/arrow-circle-right.svg";
import {ReactComponent as LockCircle} from "../../assets/icons/lock-circle.svg";


interface CollectionTableProps {
    collections: DocumentCollection[];
    isAdmin: boolean;
    onPreview: (collectionId: number) => void;
    onManagePermissions?: (editDocumentCollectionPermission: EditDocumentCollectionPermission) => void;
    onDelete: (collectionId: number) => void;
}

const CollectionTable: React.FC<CollectionTableProps> = ({
                                                             collections,
                                                             isAdmin,
                                                             onPreview,
                                                             onManagePermissions,
                                                             onDelete
                                                         }) => {
    return (
        <StyledTableCard>
            <StyledTable>
                <thead>
                <StyledTableRow>
                    <StyledTableCell>Nazwa</StyledTableCell>
                    <StyledTableCell>Ostatnia aktualizacja</StyledTableCell>
                    <StyledTableCell>Dokumenty</StyledTableCell>
                    <StyledTableCell>Właściciel</StyledTableCell>
                    <StyledTableCell>Typ</StyledTableCell>
                    <StyledTableCell>Akcje</StyledTableCell>
                </StyledTableRow>
                </thead>
                <tbody>
                {collections.map(collection => (
                    <StyledTableRow key={collection.id}>
                        <StyledTableCell>{collection.name}</StyledTableCell>
                        <StyledTableCell>
                            {new Date(collection.lastModificationDate).toLocaleDateString()}
                        </StyledTableCell>
                        <StyledTableCell>{collection.amountOfDocuments}</StyledTableCell>
                        <StyledTableCell>{collection.owner.email}</StyledTableCell>
                        <StyledTableCell>{collection.collectionType}</StyledTableCell>
                        <StyledTableCell>
                            <ActionButton
                                variant="outlined"
                                startIcon={<ArrowCircleRight/>}
                                size="small"
                                onClick={() => onPreview(collection.id)}
                            >
                                Przejdź
                            </ActionButton>
                            {isAdmin && (
                                <>
                                    {
                                        onManagePermissions != undefined && <ActionButton
                                            variant="outlined"
                                            size="small"
                                            startIcon={<LockCircle/>}
                                            onClick={() => onManagePermissions({
                                                id: collection.id,
                                                owner: collection.owner.email
                                            })}
                                        >
                                            Uprawnienia
                                        </ActionButton>
                                    }

                                    <ActionButton
                                        variant="outlined"
                                        color="error"
                                        size="small"
                                        startIcon={<TrashIcon/>}
                                        onClick={() => onDelete(collection.id)}
                                    >
                                        Usuń
                                    </ActionButton>
                                </>
                            )}
                        </StyledTableCell>
                    </StyledTableRow>
                ))}
                </tbody>
            </StyledTable>
        </StyledTableCard>
    );
};

export default CollectionTable;
