import * as React from 'react';
import {RouterProvider} from 'react-router';
import AppProviders from "./core/AppProviders";
import {router} from "./router";
import {AuthProvider} from "./core/AuthContext";
import {UserProvider} from "./core/UserContext";

export const Root: React.FC = () => (
    <React.StrictMode>
        <AppProviders>
            <AuthProvider>
                <UserProvider>
                    <RouterProvider router={router}/>
                </UserProvider>
            </AuthProvider>
        </AppProviders>
    </React.StrictMode>
);
