import {createBrowserRouter, createRoutesFromElements, Navigate, Route} from "react-router-dom";
import React from "react";
import Chat from "./pages/workspace/Chat";
import Layout from "./components/layout/Layout";
import PrivateRoute from "./components/auth/PrivateRoute";
import Login from "./pages/auth/Login";
import Register from "./pages/auth/Register";
import CreateUser from "./pages/admin/CreateUser";
import UserList from "./pages/admin/UserList";
import UserEdit from "./pages/admin/UserEdit";
import GroupList from "./pages/admin/GroupList";
import GroupDetails from "./pages/admin/GroupDetails";
import AdminCollectionList from "./pages/admin/AdminCollectionList";
import CollectionDocumentList from "./pages/admin/CollectionDocumentList";
import DocumentViewer from "./components/common/DocumentViewer";
import UserCollectionList from "./pages/workspace/UserCollectionList";
import CollectionUpdateScheduler from "./pages/admin/CollectionUpdateScheduler";

export const router = createBrowserRouter(
    createRoutesFromElements(
        <Route path="/">
            <Route path="/" element={<PrivateRoute/>}>
                <Route path="/" element={<Layout/>}>
                    <Route path="/admin">
                        <Route path="group/list" Component={GroupList}/>
                        <Route path="group/:id" Component={GroupDetails}/>
                        <Route path="user/create" element={<CreateUser/>}/>
                        <Route path="user/list" element={<UserList/>}/>
                        <Route path="user/edit" element={<UserEdit/>}/>
                        <Route path="collections/list" element={<AdminCollectionList/>}/>
                        <Route path="scheduler" Component={CollectionUpdateScheduler}/>
                    </Route>
                    <Route path="/chat" element={<Chat/>}/>
                    <Route path="/chat/:id" Component={Chat}/>
                    <Route path="/collections/list" element={<UserCollectionList/>}/>
                    <Route path="/collections/:id/documents" element={<CollectionDocumentList/>}/>
                    <Route path="/documents/view/:id" Component={DocumentViewer}/>
                    <Route index element={<Navigate to="/chat" replace/>}/>
                </Route>
            </Route>
            <Route path="/register" element={<Register/>}/>
            <Route path="*" element={<Login/>}/>
        </Route>
    ), {
        future: {
            v7_relativeSplatPath: true,
            v7_normalizeFormMethod: true,
            v7_partialHydration: true,
            v7_fetcherPersist: true,
            v7_skipActionErrorRevalidation: true,
        },
    }
);
