import React, {useEffect, useState} from 'react';
import {Document, Page, pdfjs} from 'react-pdf';
import {useLocation, useNavigate, useParams} from 'react-router-dom';
import {Button, CircularProgress, Typography} from '@mui/material';
import axios from "axios";
import {ReactComponent as ChevronLeftIcon} from "../../assets/icons/chevron-left.svg"

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
    'pdfjs-dist/build/pdf.worker.min.mjs',
    import.meta.url,
).toString();

const DocumentViewer: React.FC = () => {
    const {id} = useParams<{ id: string }>();
    const location = useLocation();
    const navigate = useNavigate();
    const [pdfUrl, setPdfUrl] = useState<string | null>(null);
    const [numPages, setNumPages] = useState<number | null>(null);
    const [pageNumber, setPageNumber] = useState(1);
    const [error, setError] = useState('');

    useEffect(() => {
        if (location.state?.pdfUrl) {
            setPdfUrl(location.state.pdfUrl);
            return;
        }

        const fetchPdf = async () => {
            try {
                const response = await axios.get(`/documents/${id}/download`, {
                    responseType: 'blob'
                });

                const blob = new Blob([response.data], {type: 'application/pdf'});
                const url = URL.createObjectURL(blob);
                setPdfUrl(url);
            } catch (err) {
                setError('Nie udało się załadować dokumentu');
                console.error('PDF load error:', err);
            }
        };

        fetchPdf();

        return () => {
            if (pdfUrl) URL.revokeObjectURL(pdfUrl);
        };
    }, [id]);

    const onDocumentLoadSuccess = ({numPages}: { numPages: number }) => {
        setNumPages(numPages);
    };

    if (error) {
        return (
            <div style={{padding: '20px', textAlign: 'center'}}>
                <Typography color="error" variant="h6">{error}</Typography>
                <Button
                    variant="contained"
                    onClick={() => navigate(-1)}
                    sx={{mt: 2}}
                >
                    Wróć
                </Button>
            </div>
        );
    }

    if (!pdfUrl) return <CircularProgress sx={{margin: '40px auto'}}/>;

    return (
        <div style={{
            padding: '20px',
            maxWidth: '1200px',
            margin: '0 auto'
        }}>
            <div style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                marginBottom: '20px'
            }}>
                <Button
                    startIcon={<ChevronLeftIcon/>}
                    variant="text"
                    onClick={() => navigate(-1)}
                >
                    Wróć do listy
                </Button>

                <div style={{display: 'flex', alignItems: 'center', gap: '16px'}}>
                    <Button
                        disabled={pageNumber <= 1}
                        onClick={() => setPageNumber(p => p - 1)}
                        variant="outlined"
                    >
                        Poprzednia
                    </Button>
                    <Typography>
                        Strona {pageNumber} z {numPages}
                    </Typography>
                    <Button
                        disabled={pageNumber >= (numPages || 0)}
                        onClick={() => setPageNumber(p => p + 1)}
                        variant="outlined"
                    >
                        Następna
                    </Button>
                </div>
            </div>

            <div style={{
                border: '1px solid #ddd',
                borderRadius: '8px',
                overflow: 'hidden'
            }}>
                <Document
                    file={pdfUrl}
                    onLoadSuccess={onDocumentLoadSuccess}
                    onLoadError={(error) => {
                        setError('Błąd ładowania PDF');
                        console.error('PDF load error:', error);
                    }}
                >
                    <Page
                        pageNumber={pageNumber}
                        width={window.innerWidth > 1200 ? 1200 : window.innerWidth - 40}
                        renderAnnotationLayer={false}
                        renderTextLayer={false}
                    />
                </Document>
            </div>
        </div>
    );
};

export default DocumentViewer;
