import styled from 'styled-components';
import {Drawer, IconButton} from '@mui/material';

interface OpenProps {
    open: boolean;
}

const filterColor = `
  invert(32%) sepia(100%) saturate(500%) hue-rotate(190deg) brightness(95%) contrast(90%)
`;

export const SidebarContainer = styled(Drawer)`
    font-size: 17px;

    .MuiDrawer-paper {
        width: ${(props) => (props.open ? '305px' : '70px')};
        transition: width 0.3s;
        overflow-x: hidden;
        display: flex;
        flex-direction: column;
        scrollbar-width: none;
    }
`;

export const StyledIconButton = styled(IconButton)`
    img {
        filter: ${filterColor};
    }
`;
export const SidebarHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px;

`;

export const StyledList = styled.div<OpenProps>`
    padding: ${(props: any) => (props.open ? '16px' : '8px')};
`


export const LogoBox = styled.div`
    width: 63px;
    height: 63px;

    img {
        width: 63px;
        height: 63px;
    }
`;

export const StyledButton = styled.button`
    display: flex;
    align-items: center;
    width: 100%;
    background: white;
    border: none;
    border-radius: 10px;
    padding: 9px 13px;
    margin: 8px 0;
    cursor: pointer;
    transition: background 0.2s ease;
    font-size: 16px;

    &:hover {
        background: #f0f0f0;
    }

    img:first-child {
        margin-right: 12px;
    }

    img {
        filter: ${filterColor};
    }

    span {
    }
`;

export const StyledReportProblemWrapper = styled.div<OpenProps>`
    padding: ${(props: any) => (props.open ? '16px' : '8px')};
    margin-top: auto;
`

export const StyledReportProblemButton = styled(StyledButton)`
    background: #f0f0f0;

    &:hover {
        background: #e6e6e6;
    }
`
export const StyledNewRequestButtonContent = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    gap: 8px;

    img:last-child {
        margin-left: auto;
    }

`;
export const StyledNewRequestButton = styled(StyledButton)<{ $active?: boolean }>`
    background: ${({$active}) => ($active ? "#f0f0f0" : "transparent")};
    border-radius: 0 20px 20px 0;
    padding: 10px 12px;
    margin-bottom: 32px;

    &:hover {
        background: #e6e6e6;
    }
`;

export const PanelAdminButton = styled(StyledButton)`
    border-radius: 16px;
    padding: 10px 12px;

    &:hover {
        background: #e6e6e6;
    }
`

export const Divider = styled.div`
    height: 1px;
    width: 100%;
    background: #f0f0f0;
    margin: 16px 0;
`;

export const ProfileContainer = styled.div`
    display: flex;
    align-items: center;
    padding: 12px;

    img {
        margin-right: 12px;
        border-radius: 50%;
    }

    span {
        font-weight: 600;
    }
`;

export const ProfileMoreBtn = styled(IconButton)`
    margin-left: auto !important;

    img {
        margin: 0;
        width: 18px;
        height: 18px;
    }
`

export const DeleteButton = styled.button`
    padding: 4px;
    margin-left: 8px;
    cursor: pointer;
    background: none;
    border: none;

    &:hover {
        opacity: 0.7;
    }
`;


export const ConversationList = styled.div`
    max-height: 450px;
    width: 268px;
    overflow-y: auto;
    scrollbar-width: none;
`
export const StyledSectionHeader = styled.div`
    padding: 8px 16px;
    font-size: 0.75rem;
    color: #666;
    text-transform: uppercase;
    letter-spacing: 0.5px;
    background: #f5f5f5;
    margin-top: 8px;
`;
