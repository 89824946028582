import React, {useEffect, useState} from 'react';
import axios from 'axios';
import {GroupItem, GroupList, SearchInput, StyledButton, StyledDialog} from './CollectionPermissionDialog.styled';
import {DocumentCollectionPermissionQueryResult, GroupQueryResult} from "../../models/models";
import {StyledTable, StyledTableCard, StyledTableCell, StyledTableRow} from "../../pages/admin/GroupList.styled";
import {Button, Select} from "@mui/material";

interface CollectionPermissionDialogProps {
    open: boolean;
    collectionId: number;
    owner: string;
    onClose: () => void;
}

const CollectionPermissionDialog: React.FC<CollectionPermissionDialogProps> = ({
                                                                                   open,
                                                                                   collectionId,
                                                                                   owner,
                                                                                   onClose
                                                                               }) => {
    const [collectionOwner, setCollectionOwner] = useState(owner);
    const [searchQuery, setSearchQuery] = useState('');
    const [searchGroupResults, setSearchGroupResults] = useState<GroupQueryResult[]>([]);
    const [permissions, setPermissions] = useState<DocumentCollectionPermissionQueryResult[]>([]);

    useEffect(() => {
        if (!open) return;

        const fetchPermissions = async () => {
            try {
                const {data} = await axios.get<DocumentCollectionPermissionQueryResult[]>(
                    `/documents/by-collection/${collectionId}/permissions`
                );
                setPermissions(data);
            } catch (error) {
                console.error('Error fetching permissions:', error);
            }
        };

        fetchPermissions();
    }, [open, collectionId]);

    useEffect(() => {
        if (!open) return;

        const searchGroups = async () => {
            try {
                const response = await axios.get('/groups', {
                    params: {page: 0, size: 10}
                });
                setSearchGroupResults(response.data.content);
            } catch (error) {
                console.error('Error searching groups:', error);
            }
        };

        searchGroups();
    }, [open, collectionId]);

    useEffect(() => {
        if (open) {
            setCollectionOwner(owner);
        }
    }, [open, owner]);

    const handleClose = () => {
        setSearchQuery('')
        setSearchGroupResults([])
        setPermissions([])
        setCollectionOwner('')
        onClose();
    };


    const searchGroups = async (query: string) => {
        try {
            const response = await axios.get('/groups', {
                params: {page: 0, size: 10, groupPartName: query}
            });
            setSearchGroupResults(response.data.content);
        } catch (error) {
            console.error('Error searching groups:', error);
        }
    };

    const handlePermissionChange = (groupId: number, newPermission: 'READ_ONLY' | 'EDIT') => {
        setPermissions(prevPermissions => {
            return prevPermissions.map(p =>
                p.groupId === groupId ? {...p, permission: newPermission} : p
            );
        });
    };

    const handleSave = async () => {
        try {
            await axios.post(
                `/documents/by-collection/${collectionId}/permissions`,
                permissions.map(permission => (
                    {
                        groupId: permission.groupId,
                        permissionType: permission.permission
                    }
                ))
            );
            onClose();
        } catch (error) {
            console.error('Error saving permissions:', error);
        }
    };

    function addNewPermission(group: GroupQueryResult) {
        setPermissions(prev => [
            ...prev,
            {groupId: group.id, name: group.name, permission: 'READ_ONLY'}
        ]);
    }

    return (
        <StyledDialog open={open} onClose={handleClose}>
            <h3>Dodaj Grupe</h3>
            <SearchInput
                value={searchQuery}
                onChange={(e) => {
                    setSearchQuery(e.target.value);
                    searchGroups(e.target.value);
                }}
                placeholder="Wyszukaj Grupę..."
            />
            <GroupList>
                {searchGroupResults.map(group => (
                    <GroupItem
                        key={group.id}
                        onClick={() => {
                            if (!permissions.some(g => g.groupId === group.id)) {
                                addNewPermission(group);
                                setSearchGroupResults(state => state.filter(prevGroup => prevGroup.id !== group.id))
                            }
                        }}
                    >
                        {group.name}
                    </GroupItem>
                ))}
            </GroupList>

            <h3>Uprawnienia</h3>

            <StyledTableCard>
                <StyledTable>
                    <thead>
                    <tr>
                        <StyledTableCell>Właściciel</StyledTableCell>
                        <StyledTableCell>Dostęp</StyledTableCell>
                        <StyledTableCell>Akcje</StyledTableCell>
                    </tr>
                    </thead>
                    <tbody>
                    <StyledTableRow key="owner">
                        <StyledTableCell>{collectionOwner}</StyledTableCell>
                        <StyledTableCell>
                            <Select
                                native
                                value={'EDIT'}
                                variant={'filled'}
                                disabled={true}
                            >
                                <option value="EDIT">Edycja</option>
                            </Select>
                        </StyledTableCell>
                        <StyledTableCell>
                        </StyledTableCell>
                    </StyledTableRow>
                    {permissions.map(permission => (
                        <StyledTableRow key={permission.groupId}>
                            <StyledTableCell>{permission.name}</StyledTableCell>
                            <StyledTableCell>
                                <Select
                                    native
                                    value={permissions.find(p => p.groupId === permission.groupId)?.permission || "READ_ONLY"}
                                    onChange={(e) => handlePermissionChange(permission.groupId, e.target.value as "READ_ONLY" | "EDIT")}
                                    variant={'outlined'}
                                >
                                    <option value="READ_ONLY">Odczyt</option>
                                    <option value="EDIT">Edycja</option>
                                </Select>
                            </StyledTableCell>
                            <StyledTableCell>
                                <Button variant="outlined" color="primary" onClick={() => {
                                    setPermissions(prev => prev.filter(p => p.groupId !== permission.groupId))
                                    if (!searchGroupResults.some(g => g.id === permission.groupId)) {
                                        setSearchGroupResults(prev => [
                                            ...prev,
                                            {id: permission.groupId, name: permission.name}
                                        ])
                                    }
                                }
                                }>
                                    Usuń
                                </Button>
                            </StyledTableCell>
                        </StyledTableRow>
                    ))}
                    </tbody>
                </StyledTable>
            </StyledTableCard>
            <StyledButton variant="outlined" color="primary" onClick={handleSave}>Zapisz</StyledButton>
            <StyledButton variant="outlined" color="primary" onClick={handleClose}>Anuluj</StyledButton>
        </StyledDialog>
    );
};

export default CollectionPermissionDialog;
