import {useCallback, useState} from 'react';
import axios from "axios";
import {Category, PdfDocument, UploadDocumentResponse} from "../models/models";
import {accessTokenKey} from "../core/AuthInterceptor";
import {v4 as uuidv4} from 'uuid';

const BASE_URL = process.env.REACT_APP_AI_ASSISTANT_BACKEND_URL;

export const useApi = () => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const sendMessage = async (question: string, useDocuments: boolean, onUpdate: (partialResponse: string) => void, file: File | null): Promise<void> => {
        setError(null);

        const token = localStorage.getItem(accessTokenKey);
        let url = `${BASE_URL}/chat?question=${encodeURIComponent(question)}&useDocuments=${useDocuments}&token=${token}`;
        if(file) {
           const {documentId} = await uploadDocument(file, file.name || uuidv4())
            url += `&documentIds=${documentId}`
        }

        const eventSource = new EventSource(url);
        let fullResponse = '';

        eventSource.onmessage = (event: any) => {
            const partialResponse = event.data;
            fullResponse += partialResponse;
            onUpdate(fullResponse);
        };

        eventSource.onerror = () => {
            setError('Błąd połączenia ze strumieniem');
            setLoading(false);
            eventSource.close();
        };

        eventSource.addEventListener('end', () => {
            setLoading(false);
            eventSource.close();
        });
    };

    const fetchCategories = useCallback(async (): Promise<Category[]> => {
        setLoading(true);
        setError(null);
        try {
            const response = await axios.get<Category[]>(`/categories`);
            return response.data;
        } catch (err: any) {
            setError(err.response?.data?.message || 'Something went wrong');
            throw err;
        } finally {
            setLoading(false);
        }
    }, []);

    const fetchDocumentsByCategoryId = async (id: string): Promise<PdfDocument[]> => {
        setLoading(true);
        setError(null);
        try {
            const response = await axios.get<PdfDocument[]>(`/categories/${id}/documents`);
            return response.data;
        } catch (err: any) {
            setError(err.response?.data?.message || 'Something went wrong');
            throw err;
        } finally {
            setLoading(false);
        }
    };

    const downloadDocumentForId = async (id: number, fileName: string): Promise<void> => {
        setLoading(true);
        setError(null);

        try {
            const token = localStorage.getItem(accessTokenKey);

            const xhr = new XMLHttpRequest();
            xhr.open('GET', `${BASE_URL}/documents/${id}/download`, true);
            xhr.responseType = 'blob';
            if (token) {
                xhr.setRequestHeader('Authorization', `Bearer ${token}`);
            }

            xhr.onload = () => {
                if (xhr.status === 200) {
                    const blob = new Blob([xhr.response]);
                    const url = window.URL.createObjectURL(blob);
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', fileName);
                    document.body.appendChild(link);
                    link.click();
                    link.remove();
                    window.URL.revokeObjectURL(url);
                } else {
                    setError(`Error ${xhr.status}: ${xhr.statusText}`);
                }
            };

            xhr.onerror = () => {
                setError('Błąd połączenia z serwerem.');
            };

            xhr.send();
        } catch (err) {
            setError('Wystąpił błąd podczas pobierania pliku.');
        } finally {
            setLoading(false);
        }
    };

    const uploadDocument = async (file: File, name: string, categoryId?: string): Promise<UploadDocumentResponse> => {
        const formData = new FormData();
        formData.append('file', file);
        formData.append('name', name);
        if (categoryId) {
            formData.append('categoryId', categoryId);
        }

        const response = await axios.post<UploadDocumentResponse>('/documents', formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });

        return response.data;
    };


    return {
        loading,
        error,
        sendMessage,
        fetchCategories,
        fetchDocumentsByCategoryId,
        downloadDocumentForId,
        uploadDocument
    };
};
